import { observable, action, autorun, makeAutoObservable } from "mobx";
import { DockReceiptModel } from "../interfaces/DockReceipt";
import AccountState from "../AccountState";
import phetch from "../utilities/phetch";
import { parseISO } from "date-fns";

class LotStatusStore {
  @observable dockReceipts: DockReceiptModel[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  @action.bound clearDockReceipts = () => this.dockReceipts = [];

  @action.bound updateDockReceipts = async () => {
    const uri = "dockReceipts/all/" + AccountState.userState.userInfo.dockReceiptCompany;
    const dockReceipts = await phetch.get<DockReceiptModel[]>(uri);
    const dateProps = ["loadedDate", "receivedDate", "lastConfirm", "lastPreAlert"];
    for (const dr of dockReceipts){
      for (const property in dr) {
        if (dateProps.includes(property) && dr.hasOwnProperty(property)) {
          const asObj = dr as any;
          const myString = asObj[property]; 
          if (myString){
            const parsed = parseISO(myString);
            asObj[property] = parsed;
          }          
        }
      }
    }
    this.dockReceipts = dockReceipts;
  }

  @action.bound refreshDockReceipts = () => {
    this.dockReceipts = [];
    this.updateDockReceipts();
  }

}

const LotStatusState = new LotStatusStore();

autorun(async (reaction) => {
  const drCompany = AccountState.userState.userInfo.dockReceiptCompany;
  if (!drCompany){
    LotStatusState.clearDockReceipts();
    return;
  }
});

export default LotStatusState;
