import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import "./LotStatus.css";
import LotStatusState from "./LotStatusState";
import DrRow from "./DrRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { DockReceiptModel } from "../interfaces/DockReceipt";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const LotStatus: React.FC = observer(() => {
  const [filterText, setFilterText] = useState("");
  const [showPreAlerts, setShowPreAlerts] = useState(true);
  const [showConfirms, setShowConfirms] = useState(false);
  useEffect(() => {
    if (LotStatusState.dockReceipts.length === 0) {
      LotStatusState.clearDockReceipts();
      LotStatusState.updateDockReceipts();
    }
  }, []);
  if (LotStatusState.dockReceipts.length === 0) {
    return (
      <div className="login-pw-modal">
        <h2 style={{ marginTop: 100 }}>
          Loading <FontAwesomeIcon icon={faSync as IconProp} className="fa-spin" />
        </h2>
      </div>
    );
  }
  function getTextMatch(dr: DockReceiptModel) {
    if (!filterText) {
      return true;
    }
    else {
      return (
        dr.lotTally.toString().startsWith(filterText) ||
        (!!dr.bookingNumber && dr.bookingNumber.toUpperCase().startsWith(filterText)) ||
        (!!dr.containerNumber && dr.containerNumber.toUpperCase().startsWith(filterText)) ||
        (!!dr.shipperReference && dr.shipperReference.toUpperCase().startsWith(filterText)) ||
        (!!dr.proNumber && dr.proNumber.toUpperCase().startsWith(filterText))
      );
    }
  }
  function getFilteredDockReceipts(): DockReceiptModel[] {
    const filtered = LotStatusState.dockReceipts.filter(dr => {
      const preAlertMatch = dr.updateType === "PreAlert" && showPreAlerts;
      const confirmMatch = dr.updateType === "Confirm" && showConfirms;
      const checkMatch = dr.updateType === "Save" || preAlertMatch || confirmMatch;
      const textMatch = getTextMatch(dr);
      return checkMatch && textMatch;
    });
    return filtered;
  }
  return (
    <div className="lot-status">
      <div>
        <h1>Lot/Tally Statuses</h1>
        <div className="lot-filter-wrap">
          <div>
            <button onClick={() => LotStatusState.refreshDockReceipts()}>Refresh</button>
          </div>
          <div>
            <input
              value={filterText}
              onChange={(e) => setFilterText(e.target.value.toUpperCase())}
              className="input"
              type="text"
              id="lot-tally-status"
              placeholder="FILTER" />
          </div>
          <div>
            <div>
              <input
                checked={showPreAlerts}
                onChange={(e) => setShowPreAlerts(e.target.checked)}
                id="showPreAlert"
                type="checkbox" />
              <label htmlFor="showPreAlert">&nbsp;Show PreAlerts</label>
            </div>
            <div>
              <input
                checked={showConfirms}
                onChange={(e) => setShowConfirms(e.target.checked)}
                id="showConfirmed"
                type="checkbox" />
              <label htmlFor="showConfirmed">&nbsp;Show Confirmed</label>
            </div>
          </div>
        </div>
        <div className="table-wrap">
          <table className="lot-table">
            <thead>
              <tr>
                <th>Lot</th>
                <th>Booking</th>
                <th>Cust Ref</th>
                <th>Container Num</th>
                <th>ProNumber</th>
                <th>Last Update</th>
                <th>Pcs</th>
                <th>Cft</th>
                <th>lbs</th>
                <th>Last<br />Update</th>
                <th>PreAlert /<br />Confirm</th>
              </tr>
            </thead>
            <tbody>
              {
                getFilteredDockReceipts().map(dr => (
                  <DrRow dockReceipt={dr} key={dr.id.toString()} />
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});

export default LotStatus;
