const emptyGuid = "00000000-0000-0000-0000-000000000000";

export default class Guid {
  public static isValid(str: string): boolean {
    const validRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return validRegex.test(str);
  }
  public static get empty(): string {
    return emptyGuid;
  }
  private value: string = Guid.empty;
  constructor(value?: string) {
    if (value) {
      if (Guid.isValid(value) || value === emptyGuid) {
        this.value = value;
      }
      else{
        const errMsg = `${value} is not a valid GUID!`;
        throw errMsg;
      }
    }
  }
  public toString() {
    return this.value;
  }
  public toJSON(): string {
    return this.value;
  }
}
