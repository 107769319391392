import React from "react";
import ToolTip from "../Tooltip";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faSquare } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface IToolTipDate{
  date: Date | undefined;
  color: string;
}

const ToolTipDate: React.FC<IToolTipDate> = (props: IToolTipDate) => {
  if (!props.date){
    return <FontAwesomeIcon icon={faSquare as IconProp} />;
  }
  const toolTipText = format(props.date, "yyyy-MM-dd HH:mm");
  return (
    <ToolTip text={toolTipText}>
      <FontAwesomeIcon color={props.color} icon={faCheckSquare as IconProp} />
    </ToolTip>
  );
};

export default ToolTipDate;
