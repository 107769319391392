import { IFormState, getDefaultState } from "./IAppState";
import AccountState from "../AccountState";
import { observable, action, computed, autorun, makeAutoObservable } from "mobx";
import { getMaxLength } from "../utilities/stringHelper";
import { 
  Description, 
  DockReceiptModel, 
  DefaultDescription, 
  DockReceiptSaveModel, 
  UpdateType } from "../interfaces/DockReceipt";
import LotTallyDto from "../interfaces/LotTallyDto";
import phetch from "../utilities/phetch";
import { format, parseISO } from "date-fns";
import { DefaultDetail, Detail } from "../Details/Detail";
import dialog from "../utilities/dialog";
import Guid from "../interfaces/Guid";
import { Shipper } from "../ShippingInfo/Shipper";
import {getDate} from "../utilities/dateHelper";

  // tslint:disable:max-line-length

export class AppStore {
  @observable state: IFormState = getDefaultState(AccountState.userState.userInfo.dockReceiptCompany);
  @observable shippers: Shipper[] = [];
  @observable saveType: UpdateType = "Save";
  @observable showButtonOptions: boolean = false;
  @observable showLogout: boolean = false;
  @observable saving: boolean = false;
  @observable sending: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  @action.bound setShippers = (newShippers: Shipper[]) => this.shippers = newShippers;
  @computed get shipper(): Shipper | undefined{
    return this.shippers.find(x => x.id === AppState.state.shipperId);
  }
  @action.bound clearForm = () => {
    const lotTallies = this.state.lotTallies;
    this.state = {
      ...getDefaultState(AccountState.userState.userInfo.dockReceiptCompany),
      lotTallies,
    };
    this.saveType = "Save";
  }
  @action.bound setShowLogout = (val: boolean) => this.showLogout = val;
  @action.bound showPhotoManagement = () => this.state.showPhotoManagement = true;
  @action.bound hidePhotoManagement = () => this.state.showPhotoManagement = false;

  @action.bound updateContainerNum = (val: string) => this.state.containerNumber = getMaxLength(val.toUpperCase(), 11);
  @action.bound updateShipperRef = (val: string) => this.state.shipperReference = getMaxLength(val, 20);
  @action.bound updateBookingNumber = (value: string) => this.state.bookingNumber = getMaxLength(value.toUpperCase(), 15);

  @action.bound updateReceivedDate = (value: string) => this.state.receivedDate = value;
  @action.bound updateReceivedTime = (value: string) => this.state.receivedTime = value;
  @action.bound updateLoadedDate = (value: string) => this.state.loadedDate = value;
  @action.bound updateDescriptions = (descriptions: Description[]) => this.state.descriptions = descriptions;
  @action.bound updateMarksAndNums = (marksAndNums: Description[]) => this.state.marksAndNums = marksAndNums;
  @action.bound updateOsAndDInfo = (osAndDInformation: Description[]) => this.state.osAndDInformation = osAndDInformation;
  @action.bound updateWarehouse = (val: string) => this.state.warehouse = getMaxLength(val.toUpperCase(), 3);
  @action.bound updateTrucker = (val: string) => this.state.trucker = getMaxLength(val, 15);
  @action.bound updateProNumber = (val: string) => this.state.proNumber = getMaxLength(val, 20);
  @action.bound toggleSaveOptions = () => this.showButtonOptions = !this.showButtonOptions;
  @action.bound toggleHazardous = () => this.state.hazardous = !this.state.hazardous;
  @action.bound selectSaveType = (saveType: UpdateType) => {
    this.saveType = saveType;
    this.showButtonOptions = false;
  }
  @action.bound hideButtonOptions = () => {
    this.showButtonOptions = false;
  }
  @action.bound updateLotTallyByNum = async (lotTally: number) => {
    const dto = this.state.lotTallies.find(x => x.lotTally === lotTally);
    if (dto){
      this.updateLotTally(dto);
    }    
  }
  @action.bound updateLotTally = async (lotTally: LotTallyDto | undefined) => {
    if (!lotTally || !lotTally.lotTally) {
      const defaultState = getDefaultState(AccountState.userState.userInfo.dockReceiptCompany);
      const lotTallies = this.state.lotTallies;
      const newState: IFormState = {
        ...defaultState,
        lotTallies,
        shipperId: "",
      };
      this.state = newState;
      return;
    }
    try {
      const drState = await phetch.get<DockReceiptModel>("dockReceipts/lotTally/" + lotTally.id);
      const oldState = this.state;
      this.state = {
        ...oldState,
        id: drState.id.toString(),
        containerNumber: drState.containerNumber,
        bookingNumber: drState.bookingNumber,
        receivedDate: format(new Date(drState.receivedDate), "yyyy-MM-dd"),
        receivedTime: format(new Date(drState.receivedDate), "hh:mm"),
        loadedDate: drState.loadedDate ? format(new Date(drState.loadedDate), "yyyy-MM-dd") : "",
        descriptions: drState.descriptions.length ? drState.descriptions : [DefaultDescription],
        marksAndNums: drState.marksAndNumbers.length ? drState.marksAndNumbers : [DefaultDescription],
        osAndDInformation: drState.osAndDInformation.length ? drState.osAndDInformation : [DefaultDescription],
        lotTally: drState.lotTally,
        warehouse: drState.warehouseLocation,
        details: drState.details.length ? drState.details : [DefaultDetail],
        shipperId: drState.shipperId.toString(),
        lastUpdate: new Date(drState.lastUpdate),
        lastUpdateUser: drState.lastUpdateUser,
        proNumber: drState.proNumber,
        trucker: drState.trucker,
        hazardous: drState.hazardous,
        updateType: drState.updateType,
        shipperReference: drState.shipperReference,
        confirmed: !!drState.lastConfirm,
        preAlerted: !!drState.lastPreAlert,
      };
      if (drState.updateType === "Confirm"){
        this.saveType = "Confirm";
      }
      else if (drState.updateType === "PreAlert"){
        this.saveType = "PreAlert";
      }
      else{
        this.saveType = "Save";
      }
    }
    catch (err: any) {
      dialog.error(err.message);
    }
  }
  @action.bound updateDetails = (details: Detail[]) => this.state.details = details;
  @action.bound updateShipper = (shipperId: string) => this.state.shipperId = shipperId;
  @action.bound updateDockReceipt = () => {
    if (this.saveType === "Delete") {
      dialog.confirm("Are you sure you want to delete this LotTally?", this.delete);
    }
    else {
      this.userSave();
    }
  }
  @action.bound delete = async () => {
    this.saving = true;
    try {
      await phetch.delete<DockReceiptModel>("dockReceipts/lotTally/" + this.state.id);
      const prevState = this.state;
      const lotTallies = prevState.lotTallies.filter(x => x.id !== prevState.id);
      this.saving = false;
      this.state.lotTallies = lotTallies;
      this.updateLotTally(undefined);
    }
    catch (ex) {
      dialog.alert("There was an error deleting the lot tally");
    }
  }
  @action.bound userSave = (updateType?: UpdateType) => {
    if (this.state.hazardous && this.state.details.find(x => !x.emco)) {
      const msg = "You have selected Hazardous, but you do not have EMCO filled " +
        "out for all the items. Are you sure you wish to save?";
      dialog.confirm(msg, () => this.save(updateType));
    }
    else {
      this.save(updateType);
    }
  }

  @action.bound loadLotTallies = async () => {
    const uri = "dockReceipts/lotTallies/" + AccountState.userState.userInfo.dockReceiptCompany;
    const lotTallies = await phetch.get<LotTallyDto[]>(uri);
    lotTallies.sort((a, b) => b.lotTally - a.lotTally);
    this.state.lotTallies = lotTallies;
  }

  @action.bound save = async (updateType?: UpdateType) => {
    const saveModel: DockReceiptSaveModel = {
      id: this.state.id ? new Guid(this.state.id) : new Guid(),
      shipperId: new Guid(this.state.shipperId),
      company: AccountState.userState.userInfo.dockReceiptCompany,
      containerNumber: this.state.containerNumber,
      shipperReference: this.state.shipperReference,
      receivedDate: getDate(this.state.receivedDate + "T" + this.state.receivedTime),
      loadedDate: this.state.loadedDate ? parseISO(this.state.loadedDate) : undefined,
      bookingNumber: this.state.bookingNumber,
      descriptions: this.state.descriptions,
      marksAndNumbers: this.state.marksAndNums,
      osAndDInformation: this.state.osAndDInformation,
      warehouseLocation: this.state.warehouse,
      proNumber: this.state.proNumber,
      details: this.state.details,
      lotTally: this.state.lotTally,
      trucker: this.state.trucker,
      hazardous: this.state.hazardous,
      updateType: updateType ? updateType : this.saveType,
    };
    if (saveModel.updateType === "Save"){
      this.saving = true;
    }
    else{
      this.sending = true;
    }
    const prevState = this.state;
    try {
      const saved = await phetch.post<DockReceiptModel>("dockReceipts/save", saveModel);
      const lotTallies = prevState.lotTallies;
      if (!lotTallies.find(x => x.lotTally === prevState.lotTally)) {
        lotTallies.unshift({ id: saved.id.toString(), lotTally: saved.lotTally });
      }
      this.state = {
        ...prevState,
        id: saved.id.toString(),
        containerNumber: saved.containerNumber,
        bookingNumber: saved.bookingNumber,
        receivedDate: format(new Date(saved.receivedDate), "yyyy-MM-dd"),
        receivedTime: format(new Date(saved.receivedDate), "HH:mm"),
        loadedDate: saved.loadedDate ? format(new Date(saved.loadedDate), "yyyy-MM-dd") : "",
        warehouse: saved.warehouseLocation,
        descriptions: saved.descriptions,
        marksAndNums: saved.marksAndNumbers,
        osAndDInformation: saved.osAndDInformation,
        details: saved.details,
        lotTallies,
        lotTally: saved.lotTally,
        lastUpdate: new Date(saved.lastUpdate),
        lastUpdateUser: saved.lastUpdateUser,
        updateType: saved.updateType,
        hazardous: saved.hazardous,
        confirmed: !!saved.lastConfirm,
      };
    }
    catch (ex: any) {
      dialog.error(ex.errors || "There was an error saving your dock receipt");
    }
    this.saving = false;
    this.sending = false;
  }
}



const AppState = new AppStore();

autorun(async (reaction) => {
  const drCompany = AccountState.userState.userInfo.dockReceiptCompany;
  if (!drCompany){
    AppState.setShippers([]);
    return;
  }
  try {
    const uri = "dockReceipts/shippers/" + drCompany;
    const myShippers = await phetch.get<Shipper[]>(uri);
    AppState.setShippers(myShippers);
  }
  catch (err: any) {
    if (err.message) {
      dialog.alert(err.message);
    }
    dialog.alert("There was an error getting the list of shippers");
  }
});

// window.setInterval(() => console.log("LT", AppState.state.lotTally), 100);


export default AppState;
