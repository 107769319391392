import React, { useEffect } from "react";
import { observer } from "mobx-react";
import AppState from "./AppState/AppStateStore";
import AutoComplete from "./AutoComplete/AutoComplete";
import LotTallyDto from "./interfaces/LotTallyDto";
import dialog from "./utilities/dialog";
import ShipperInfo from "./ShippingInfo/ShipperInfo";
import Descriptions from "./Descriptions/Descriptions";
import DetailEntry from "./Details/DetailEntry";
import Modal from "./Modal/Modal";
import PhotoManagement from "./Photos/Photos";
import LastUpdate from "./LastUpdate";
import SaveOptions from "./SaveOptions/SaveOptions";
import { Navigate, useParams } from "react-router-dom";

const MainPage = observer(() => {
  const {lotTally} = useParams();
  const lotTallyNum = lotTally ? parseInt(lotTally, 10.0) : 0;
  async function loadLotTallies(){
    if (AppState.loadLotTallies.length === 0){
      await AppState.loadLotTallies();
    }    
    if (lotTallyNum){
      AppState.updateLotTallyByNum(lotTallyNum);
    }
  }
  useEffect(() => {
    loadLotTallies();
  }, []);
  if (AppState.state.lotTally && AppState.state.lotTally !== lotTallyNum) {
    return <Navigate to={("/lotTally/" + AppState.state.lotTally)} />;
  }
  function blurDatePicker(e: React.FocusEvent<HTMLInputElement>){
    if (!e.target.value){
      e.target.value = "";
    }
  }
  function getInputClass(){
    if (AppState.state.lotTally >= 10000000){
      return "input-sm input-fix-overflow2";
    }
    else if (AppState.state.lotTally >= 1000000){
      return "input-sm input-fix-overflow";
    }
    return "input-sm";
  }
  return (
    <div>
      <div className="main">
        <div className="app-row">
          <div className="app-cell">
            <div className="input-combo">
              <label htmlFor="lotTally">Lot/Tally:</label>
              <AutoComplete<LotTallyDto>
                items={[{ id: "", lotTally: 0 }, ...AppState.state.lotTallies]}
                onSelect={AppState.updateLotTally}
                textBoxValue={x => x.lotTally.toString()}
                itemFilter={(x => x.lotTally.toString())}
                showDropDownArrow={true}
                inputClass={getInputClass()}
                inputId="lot-tally"
                defaultText={AppState.state.lotTally.toString()}
                onUnknownSelection={() => dialog.alert("Could not locate that Lot Tally")}
                renderItem={(item: LotTallyDto, isHighlighted: boolean) =>
                  <div key={item.id}
                    className={isHighlighted ? "dd-item-highlight dd-item lt-item" : "dd-item lt-item"}>
                    {item.lotTally}
                  </div>
                }
              />
            </div>
            <button style={{marginTop: "10px"}} onClick={AppState.clearForm}>Clear</button>
          </div>
          <div className="app-cell">
            <ShipperInfo  />
            <div className="input-combo space-above">
              <label htmlFor="proNumber">ProNumber</label>
              <input
                value={AppState.state.proNumber || ""}
                className="input pro-input"
                type="text"
                id="proNumber"
                onChange={(e) => AppState.updateProNumber(e.target.value)} />
            </div>
          </div>
          <div className="app-cell">
            <div className="input-combo space-below">
              <label htmlFor="bookingNumber">Booking Number</label>
              <input
                value={AppState.state.bookingNumber}
                className="input"
                type="text"
                id="bookingNumber"
                onChange={(e) => AppState.updateBookingNumber(e.target.value)} />
            </div>
            <div className="input-combo space-below">
              <label htmlFor="shipperRef">Shipper Reference</label>
              <input
                value={AppState.state.shipperReference}
                className="input"
                type="text"
                id="containerNum"
                onChange={(e) => AppState.updateShipperRef(e.target.value)} />
            </div>
            <div className="input-combo">
              <label htmlFor="containerNum">Container Number</label>
              <input
                value={AppState.state.containerNumber}
                className="input"
                type="text"
                id="containerNum"
                onChange={(e) => AppState.updateContainerNum(e.target.value)} />
            </div>
          </div>
          <div className="app-cell">
            <div className="input-combo space-below">
              <label htmlFor="pickupDate">Received Date/Time</label>
              <input
                value={AppState.state.receivedDate}
                className="input"
                type="date"
                id="pickupDate"
                onChange={(e) => AppState.updateReceivedDate(e.target.value)} />
              <label htmlFor="pickupTime" style={{ display: "none" }}>Pickup Time</label>
              <input
                value={AppState.state.receivedTime}
                className="input"
                type="time"
                id="pickupTime"
                onChange={(e) => AppState.updateReceivedTime(e.target.value)} />
            </div>
            <div className="input-combo">
              <label htmlFor="containerNum">Loaded Date</label>
              <input
                value={AppState.state.loadedDate}
                className="input"
                type="date"
                id="loadedDate"
                onBlur={blurDatePicker}
                onChange={(e) => AppState.updateLoadedDate(e.target.value)} />
            </div>
            <div className="input-combo space-above">
              <label htmlFor="trucker">Trucker</label>
              <input
                value={AppState.state.trucker || ""}
                className="input"
                type="text"
                id="trucker"
                onChange={(e) => AppState.updateTrucker(e.target.value)} />
            </div>
          </div>
        </div>
        <div className="app-row">
          <div className="app-cell app-cell-wide">
            <Descriptions
              title="Descriptions"
              lotTally={AppState.state.lotTally}
              descriptions={AppState.state.descriptions}
              updateDescriptions={AppState.updateDescriptions} />
          </div>
        </div>
        <div className="app-row">
          <div className="app-cell app-cell-wide">
            <Descriptions
              title="Marks &amp; Nums"
              lotTally={AppState.state.lotTally}
              descriptions={AppState.state.marksAndNums}
              updateDescriptions={AppState.updateMarksAndNums} />
          </div>
        </div>
        <div className="app-row">
          <div className="app-cell app-cell-wide">
            <Descriptions
              title="OS&amp;D Information"
              lotTally={AppState.state.lotTally}
              descriptions={AppState.state.osAndDInformation}
              updateDescriptions={AppState.updateOsAndDInfo} 
              osAndD={true}/>
          </div>
        </div>
        <div className="app-row">
          <div className="app-cell">
            <DetailEntry />
          </div>
        </div>
        <div style={{marginBottom: 40}} className="app-row save-button-row">
          <div>
            {
              AppState.state.lotTally > 0 ?
                (<button onClick={AppState.showPhotoManagement} className="photo-button">Manage&nbsp;Photos</button>) :
                (<div>&nbsp;</div>)
            }
          </div>
          <LastUpdate />
          <SaveOptions/>
        </div>
      </div>
      <Modal visible={AppState.state.showPhotoManagement} onTryClose={AppState.hidePhotoManagement} title="Photos">
        <PhotoManagement lotTally={AppState.state.lotTally} />
      </Modal>
    </div>
  );
});

export default MainPage;
