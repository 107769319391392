import React from "react";
import { observer } from "mobx-react";
import AppState from "../AppState/AppStateStore";
import LoadButton from "../LoadButton/LoadButton";
import "./SaveOptions.scss";
import ToolTip from "../Tooltip";

const SaveOptions: React.FC = observer(() => {
  function confirmChange(checked: boolean) {
    console.log("Confirm");
    const saveType = checked ? "Confirm" : "Save";
    AppState.selectSaveType(saveType);
  }
  function preAlertChange(checked: boolean) {
    console.log("PreAlert");
    const saveType = checked ? "PreAlert" : "Save";
    AppState.selectSaveType(saveType);
  }
  function deleteChange(checked: boolean) {
    const saveType = checked ? "Delete" : "Save";
    AppState.selectSaveType(saveType);
  }
  function getSendButtonClass(){
    let myClass = "save-button";
    if (AppState.saveType === "Delete"){
      myClass += " danger";
    }
    return myClass;
  }
  function getSendButtonText(){
    if (AppState.saveType === "Delete"){
      return "Delete";
    }
    else{
      let myText = "Save and Send";
      if (AppState.saveType === "PreAlert"){
        myText += " Pre-Alert";
      }
      else if (AppState.saveType === "Confirm"){
        myText += " Confirm";
      }
      return myText;
    }    
  }
  return (
    <div className="save-button-wrapper">
      <div>
        <ToolTip text={"Press Ctrl+S to save"}>
          <div className="save-button-wrapper">
            <LoadButton
              className={"save-button"}
              onClick={() => AppState.userSave("Save")}
              working={AppState.saving}
              disabled={AppState.sending}
              text={"Save"} />
          </div>
        </ToolTip>
      </div>
      <div>
        <ToolTip text={"Press Ctrl+Alt+S to save and send PreAlerts or Confirmations if those options are checked"}>
          <div className="save-button-wrapper">
            <LoadButton
              className={getSendButtonClass()}
              onClick={() => AppState.userSave()}
              working={AppState.sending}
              disabled={AppState.saveType === "Save"}
              text={getSendButtonText()}/>
          </div>
        </ToolTip>
        {
          !AppState.state.confirmed && (
            <ToolTip text="Press Alt+P to set PreAlert Mode">
              <div>
                <input
                  checked={AppState.saveType === "PreAlert"}
                  onChange={(e) => preAlertChange(e.target.checked)}
                  id="preAlert" type="checkbox" />&nbsp;
            <label htmlFor="preAlert">Send PreAlert</label>
              </div>
            </ToolTip>
          )
        }
        <ToolTip text="Press Alt+C to set Confirm Mode">
          <div>
            <input
              checked={AppState.saveType === "Confirm"}
              onChange={(e) => confirmChange(e.target.checked)}
              id="confirm" type="checkbox" />&nbsp;
            <label htmlFor="confirm">Send Confirmation</label>
          </div>
        </ToolTip>
        {
          (!AppState.state.confirmed && !AppState.state.preAlerted && AppState.state.updateType === "Save") && (
            <div style={{ marginTop: "20px" }}>
              <input
                checked={AppState.saveType === "Delete"}
                onChange={(e) => deleteChange(e.target.checked)}
                id="delete" type="checkbox" />&nbsp;
              <label htmlFor="delete">Delete</label>
            </div>
          )
        }
      </div>
    </div>
  );
});

export default SaveOptions;
