import React from "react";
import { observer } from "mobx-react";
import { getCftFromDetail } from "./Detail";
import AppState from "../AppState/AppStateStore";
import DetailLine from "./DetailLine";
import "./Details.scss";

const DetailEntry: React.FC = observer(() => {

  const totalPcs = AppState.state.details.reduce((acc, d) => acc + d.pieces, 0) || 0;

  const getTotalCft = () => {
    const cft = AppState.state.details.reduce((acc, d) => acc + (getCftFromDetail(d)), 0) || 0;
    return cft.toFixed(2);
  };

  const getTotalLbs = () => {
    const lbs = AppState.state.details.reduce(((acc, d) => acc + (d.pounds)), 0) || 0;
    return lbs.toFixed(2);
  };

  return (
    <div className="detail-section">
      <div className="detail-table">
        <div className="detail-table-head">
          <div>
            <div>Pcs</div>
            <div>Type</div>
            <div>Cft</div>
            <div>Length</div>
            <div>Width</div>
            <div>Height</div>
            <div>Lbs</div>
            <div>EMCO</div>
            <div>Class</div>
            <div>Flash</div>
          </div>
        </div>
        <div className="detail-table-body">
          {
            AppState.state.details.map((d, i) => (
                <DetailLine rowIndex={i} key={i} />
            ))
          }
        </div>
      </div>
      <div className="sd-totals">
        <div className="sd-total-row">
          <div className="totals-title"><h4>Totals</h4></div>
          <div>Pieces</div>
          <div>Cft</div>
          <div>Lbs</div>
        </div>
        <div className="sd-total-row">
          <div>&nbsp;</div>
          <div className="sd-total">
            <div>{totalPcs}</div>
          </div>
          <div className="sd-total">
            <div>{getTotalCft()}</div>
          </div>
          <div className="sd-total">
            <div>{getTotalLbs()}</div>
          </div>
        </div>
        <div className="sd-total-row sd-add-button-row">
            <div className="hazardous-check">
              <input type="checkbox" id="hazardous" 
                onChange={AppState.toggleHazardous}
                checked={AppState.state.hazardous}></input>
              <label htmlFor="hazardous">Hazardous</label>
            </div>
        </div>
      </div>
    </div>
  );
});

export default DetailEntry;
