import * as React from "react";
// import Popper from 'popper.js';
// import Tooltip from "tooltip.js";
import tippy, { Instance } from "tippy.js";

export interface IToolTipProps {
  text: string;
}

// export interface IToolTipState {
//   visible: boolean
// }

export default class ToolTip extends React.PureComponent<IToolTipProps>{  
  hoverElement: React.RefObject<HTMLElement>;
  tippyInstance: Instance | undefined;
  constructor(props: IToolTipProps){
    super(props);
    this.hoverElement = React.createRef();
  }
  componentDidUpdate(prevProps: IToolTipProps){
    if (prevProps.text !== this.props.text){
      this.setToolTipText();
    }
  }
  componentWillUnmount(){
    if (this.tippyInstance){
      this.tippyInstance.destroy();
    }    
  }
  componentDidMount(){
    this.setToolTipText();
  }
  setToolTipText = () => {
    if (this.hoverElement.current){
      const instance = tippy(this.hoverElement.current, {
        arrow: true,
        content: this.props.text,
        delay: 1000,
      });
      this.tippyInstance = instance as Instance;
    }    
  }
  public render() {
    return (
      <span tabIndex={-1} ref={this.hoverElement}>
        {this.props.children}
      </span>
    );
  }
}
