import React from "react";
import { DockReceiptModel } from "../interfaces/DockReceipt";
import format from "date-fns/format";
import { Link } from "react-router-dom";
import { getCftFromDetail } from "../Details/Detail";
import ToolTipDate from "./ToolTipDate";

const DrRow: React.FC<{ dockReceipt: DockReceiptModel }> = (props: { dockReceipt: DockReceiptModel }) => {
  const pieces = props.dockReceipt.details.reduce((acc, d) => {
    return acc + d.pieces;
  }, 0);
  const cft = props.dockReceipt.details.reduce((acc, d) => acc + (getCftFromDetail(d) * d.pieces), 0) || 0;
  const lbs = props.dockReceipt.details.reduce(((acc, d) => acc + (d.pounds * d.pieces)), 0) || 0;


  return (
    <tr key={props.dockReceipt.id.toString()}>
      <td>
        <Link to={`/lotTally/${props.dockReceipt.lotTally}`}>{props.dockReceipt.lotTally}</Link>
      </td>
      <td>{props.dockReceipt.bookingNumber}</td>
      <td>{props.dockReceipt.shipperReference}</td>
      <td>{props.dockReceipt.containerNumber}</td>
      <td>{props.dockReceipt.proNumber}</td>
      <td>{props.dockReceipt.lastUpdate}</td>
      <td>{pieces}</td>
      <td>{cft.toFixed(2)}</td>
      <td>{lbs.toFixed(2)}</td>
      <td>{props.dockReceipt.updateType}</td>
      <td>
        <ToolTipDate date={props.dockReceipt.lastPreAlert} color="rgb(46, 194, 26)" />&nbsp;
        <ToolTipDate date={props.dockReceipt.lastConfirm} color="rgb(26, 71, 194)" />
      </td>
    </tr>
  );
};

export default DrRow;
