import environment from "./environment";


export interface PhetchOptions {
  verb: "GET" | "DELETE" | "POST" | "PUT" | "OPTIONS";
  params?: any;
  body?: any;
}

interface ErrorObject {
  status: number;
  message: string;
  errors?: string[];
}

const phetchOptions = class FetchOptions {
  params: any;
  body: any;
  constructor(public data: any, public verb: string) {
    this.verb = verb ? verb : "GET";
    if (this.verb === "GET") {
      this.params = data;
    }
    else {
      this.body = data;
    }
  }
};

export { phetchOptions as FetchOptions };


const phetch = {
  async request<T>(url: string, options: PhetchOptions): Promise<T> {
    const fOptions = getFetchOptions(options, url);
    const params = options ? options.params : undefined;
    const fullUri = getUri(url, params);
    const response = await fetch(fullUri, fOptions);
    if (response.status === 200) {
      if (isJson(response)) {
        try {
          const responseObj = await response.json();
          return responseObj;
        }
        catch (err: any) {
          const myErr: ErrorObject = {
            message: "Error Code JSON1. Please report this to the help desk",
            status: response.status,
          };
          throw myErr;
        }
      }
      else {
        return (true as any);
      }
    }
    else {
      console.log("THROWING");
      const errorObj = await getErrorObject(response);
      throw errorObj;
    }
  },

  get<T>(uri: string, parameters?: any): Promise<T> {
    const options: PhetchOptions = {
      verb: "GET",
      params: parameters,
      body: null,
    };
    return phetch.request(uri, options);
  },

  post<T>(uri: string, body?: any, parameters?: any): Promise<T> {
    const options: PhetchOptions = {
      verb: "POST",
      params: parameters,
      body,
    };
    return phetch.request(uri, options);
  },

  put<T>(uri: string, body?: any, parameters?: any): Promise<T> {
    const options: PhetchOptions = {
      verb: "PUT",
      params: parameters,
      body,
    };
    return phetch.request(uri, options);
  },

  delete<T>(uri: string, body?: any, parameters?: any): Promise<T> {
    const options: PhetchOptions = {
      verb: "DELETE",
      params: parameters,
      body,
    };
    return phetch.request(uri, options);
  },


  form(uri: string, formData: any) {
    const fullUri = environment.api + uri;
    const formHeader: any = {};
    const token = localStorage.getItem("apiToken");
    if (token) {
      const authHeader = "Bearer " + token;
      formHeader.Authorization = authHeader;
    }
    return new Promise<any>((resolve, reject) => {
      fetch(fullUri, {
        method: "POST",
        body: formData,
        headers: formHeader,
      }).then(
        (response) => {
          if (response.status !== 200) {
            response.json().then(
              (json) => {
                json.status = response.status;
                reject(json);
              },
              (err) => {
                console.error("Unknown error in fetch", err);
                reject({
                  message: getUnknownErrorMsg(response.status),
                  status: response.status,
                });
              },
            );
            return;
          }
          response.json().then((json) => {
            resolve(json);
          });
        })
        .catch((err: any) => {
          reject({
            message: "There was an unknown error with your request.",
          });
          console.error("Something went wrong: ", err);
        });
    });
  },



  requestFile(uri: string) {
    if (!uri.startsWith("http://") && !uri.startsWith("https://")) {
      uri = environment.api + uri;
    }
    return new Promise<any>((resolve, reject) => {
      fetch(uri, {
        headers: getFetchHeaders(uri, "null"),
      }).then(
        (response) => {
          if (response.status !== 200) {
            response.json().then(
              (json) => {
                json.status = response.status;
                reject(json);
              },
              (err) => {
                console.error("Unknown error in fetch", err);
                reject({
                  message: getUnknownErrorMsg(response.status),
                  status: response.status,
                });
              },
            );
            return;
          }
          else {
            resolve(response.blob());
          }
        })
        .catch((err: any) => {
          reject({
            message: "There was an unknown error with your request.",
          });
          console.error("Something went wrong: ", err);
        });
    });
  },
};


function getFetchOptions(options: PhetchOptions, uri: string) {
  const FetchOptions: RequestInit = {
    headers: getFetchHeaders(uri, ""),
    method: (!!options && !!options.verb) ? options.verb : "GET",
    body: (!!options && !!options.body) ? JSON.stringify(options.body) : undefined,
  };
  if (options.body && options.body.receivedDate){
    console.log("Stringify", options.body.receivedDate, JSON.stringify(options.body.receivedDate));
  }
  
  return FetchOptions;
}


function getFetchHeaders(uri: string, contentType: string) {
  if (!contentType) {
    contentType = "application/json";
  }
  const headers: any = {
    "Accept": "application/json",
    "Content-Type": contentType,
    "If-Modified-Since": "Mon, 26 Jul 1997 05:00:00 GMT",
    "Cache-Control": "no-cache",
    "CTI-Application": "dock-receipt",
  };
  const token = localStorage.getItem("apiToken");
  if (token) {
    const authHeader = "Bearer " + token;
    headers.Authorization = authHeader;
  }
  return headers;
}

function getUri(url: string, params: any) {
  let uri = url;
  if (url.indexOf(".") === -1) {
    uri = environment.api + url;
  }
  if (params) {
    const queryParams = Object.keys(params)
      .filter((key) => {
        return typeof (params[key]) !== "undefined" && params[key] !== null;
      })
      .map((key) => {
        let value = params[key];
        if (Object.prototype.toString.call(value) === "[object Date]") {
          value = value.toISOString();
        }
        else {
          value = encodeURIComponent(value);
        }
        const q = encodeURIComponent(key) + "=" + value;
        return q;
      });
    const queryString = queryParams.join("&");
    uri = uri + "?" + queryString;
  }
  return uri;
}

function getUnknownErrorMsg(status: number) {
  if (status === 401) {
    return "There was an unknown error with your request. Please make sure you are logged in.";
  }
  else {
    return "There was an unknown error with your request. Status code: " + status;
  }
}

function isJson(response: any) {
  const contentType = response.headers.get("content-type");
  if (contentType && contentType.indexOf("application/json") !== -1) {
    return true;
  }
  return false;
}



async function getErrorObject(response: Response): Promise<ErrorObject> {
  const contentType = response.headers.get("content-type");
  const errorObj: ErrorObject = {
    message: getUnknownErrorMsg(response.status),
    status: response.status,
  };
  if (contentType && contentType.includes("application/json")) {
    try {
      const json = await response.json();
      errorObj.message = json.message;
      if (json.errors) {
        errorObj.errors = json.errors;
      }
    }
    catch (err: any) {
      console.log("There was an exception parsing the error object", response, err);
    }
  }
  return errorObj;
}

export default phetch;
