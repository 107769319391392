import Guid from "./Guid";
import { Detail } from "../Details/Detail";

export interface DockReceiptSaveModel {
  id: Guid;
  shipperId: Guid;
  company: string;
  containerNumber: string;
  receivedDate: Date;
  loadedDate: Date | undefined;
  bookingNumber: string;
  descriptions: Description[];
  marksAndNumbers: Description[];
  osAndDInformation: Description[];
  warehouseLocation: string;
  proNumber: string;
  details: Detail[];
  lotTally: number;
  shipperReference: string;
  trucker: string;
  hazardous: boolean;
  updateType: UpdateType;
}

export type UpdateType = "Save" | "PreAlert" | "Confirm" | "Delete";

export interface DockReceiptModel extends DockReceiptSaveModel {
  lastUpdate: Date;
  lastUpdateUser: string;  
  lastPreAlert: Date | undefined;
  lastConfirm: Date | undefined;
}

export interface Description {
  id: Guid;
  text: string;
}

export const DefaultDescription: Description = {
  id: new Guid(),
  text: "",
};
