export interface UserState {
    isLoggedIn: boolean;
    userInfo: UserInformation;
}

export interface UserInformation {
    /** User Email */
    email: string;
    /** Roles Allowed (Agent, Customer, etc) */
    roles: string[];
    /** Login Username. */
    userName: string;
    /** Real Name associated with account. */
    name: string;
    /** Valid Customer Codes Associated With Account that can be used for rates */
    validCustomerCodeRates: string[];
    /** Customer Code Bookings Are Made Using */
    bookingCustCode: string;
    /** Valid Customer Codes Associated With Account  */
    validCustomerCodes: string[];
    /** Customer Code using for getting Web Rates (always a ValidCustomerCodeRate). */
    validCustomerCodeWebRate: string;
    /** Import Credit Approved */
    importCreditApproved: boolean;
    /** Dock Receipt Company */
    dockReceiptCompany: string;
}

export const DefaultUserState: UserState = {
    isLoggedIn: false,
    userInfo: {
        email: "",
        roles: [],
        userName: "",
        name: "",
        validCustomerCodeRates: [],
        validCustomerCodes: [],
        validCustomerCodeWebRate: "",
        bookingCustCode: "",
        importCreditApproved: false,
        dockReceiptCompany: "",
    },
};

