import React from "react";
import Modal from "../Modal/Modal";
import { Shipper, DefaultShipper } from "./Shipper";
import LoadButton from "../LoadButton/LoadButton";
import UsaStates, {IUsaState} from "./UsaStates";
import AutoComplete from "../AutoComplete/AutoComplete";

interface IShippingModal {
  visible: boolean;
  onTryClose: (shipper?: Shipper) => void;
  buttonWorking: boolean;
  initialShipperData?: Shipper;
}



class ShipperModal extends React.Component<IShippingModal, Shipper> {
  state: Shipper = DefaultShipper;
  componentDidMount() {
    this.copyShipperToState();
  }
  componentDidUpdate(prevProps: IShippingModal) {
    if (!this.props.initialShipperData && !prevProps.initialShipperData) {
      return;
    }
    if (this.props.initialShipperData &&
      prevProps.initialShipperData &&
      this.props.initialShipperData.id === prevProps.initialShipperData.id) {
      return;
    }
    this.copyShipperToState();
  }
  copyShipperToState = () => {
    if (this.props.initialShipperData){
      const shipperInfo = { ...this.props.initialShipperData };
      this.setState(shipperInfo);
    }    
  }
  updateShipper = () => this.props.onTryClose(this.state);
  updateName = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({name: e.target.value});
  updateStreetPo = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({streetPo: e.target.value});
  updateStreetPo2 = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({streetPo2: e.target.value});
  city = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({city: e.target.value});
  regionCode = (regionCode: string) => this.setState({regionCode});
  postalCode = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({postalCode: e.target.value});
  country = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({country: e.target.value});
  phone = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({phone: e.target.value});
  contact = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({contact: e.target.value});
  emailAddress = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({emailAddress: e.target.value});
  fax = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({fax: e.target.value});
  render() {
    return (
      <Modal title="Add Shipper" visible={this.props.visible} onTryClose={() => this.props.onTryClose()}>
        <div className="add-shipper-form">
          <div className="add-shipper-column">
            <div className="add-shipper-info add-shipper-section">
              <label htmlFor="name">Name</label>
              <div>
                <input onChange={this.updateName} value={this.state.name} type="text" id="name"></input>
              </div>
            </div>
            <div className="add-shipper-section">
              <div className="add-shipper-info">
                <label htmlFor="streetPo">Street</label>
                <div>
                  <input onChange={this.updateStreetPo} value={this.state.streetPo} type="text" id="streetPo"></input>
                </div>
              </div>
              <div className="add-shipper-info">
                <label className="hidden" htmlFor="streetPo2">Street 2</label>
                <div>
                  <input 
                  onChange={this.updateStreetPo2} 
                    value={this.state.streetPo2} 
                    type="text" 
                    id="streetPo2"></input>
                </div>
              </div>
            </div>
            <div className="add-shipper-info add-shipper-section">
              <label htmlFor="city">City</label>
              <div>
                <input onChange={this.city} value={this.state.city} type="text" id="city"></input>
              </div>
            </div>
            <div className="add-shipper-info add-shipper-section">
              <label htmlFor="stateRegion">State/Region</label>
              <div>
                <AutoComplete<IUsaState> 
                  items={UsaStates}
                  onSelect={(s: IUsaState) => this.regionCode(s.abbreviation)}
                  onInputUpdate={value => this.regionCode(value)}
                  itemFilter={s => [s.abbreviation, s.name]}
                  textBoxValue={s => s.abbreviation}
                  keySelector={s => s.abbreviation}
                  showDropDownArrow={false}
                  defaultText={this.state.regionCode}
                  inputId="add-shipper"
                  renderItem={(s, h) => (
                    <div className={h ? "dd-item-highlight dd-item" : "dd-item"}>{`${s.abbreviation}/${s.name}`}</div>
                  )}
                />
              </div>
            </div>
            <div className="add-shipper-info add-shipper-section">
              <label htmlFor="zip">Postal Code</label>
              <div>
                <input onChange={this.postalCode} value={this.state.postalCode} type="text" id="zip"></input>
              </div>
            </div>
            <div className="add-shipper-info add-shipper-section">
              <label htmlFor="country">Country</label>
              <div>
                <input onChange={this.country} value={this.state.country} type="text" id="country"></input>
              </div>
            </div>
          </div>
          <div className="add-shipper-column">
            <div className="add-shipper-info add-shipper-section">
              <label htmlFor="phone">Phone</label>
              <div>
                <input onChange={this.phone} value={this.state.phone} type="text" id="phone"></input>
              </div>
            </div>
            <div className="add-shipper-info add-shipper-section">
              <label htmlFor="fax">Fax</label>
              <div>
                <input onChange={this.fax} value={this.state.fax} type="text" id="fax"></input>
              </div>
            </div>
            <div className="add-shipper-info add-shipper-section">
              <label htmlFor="contact">Contact</label>
              <div>
                <input onChange={this.contact}  value={this.state.contact} type="text" id="contact"></input>
              </div>
            </div>
            <div className="add-shipper-info add-shipper-section">
              <label htmlFor="emailAddress">Email Address</label>
              <div>
                <input 
                  onChange={this.emailAddress}  
                  value={this.state.emailAddress} 
                  type="text" 
                  id="emailAddress" />
              </div>
            </div>
          </div>
        </div>
        <div className="add-shipper-footer">
          <LoadButton 
            working={this.props.buttonWorking} 
            onClick={this.updateShipper} 
            text="Save" 
            className="button blue" />
        </div>
      </Modal>
    );
  }
}

export default ShipperModal;
