import React from "react";
import { observer } from "mobx-react";
import ToolTip from "../Tooltip";
import AppState from "../AppState/AppStateStore";
import { Detail, getCft, DefaultDetail } from "./Detail";

interface IDetailLine{
  rowIndex: number;
}

const DetailLine: React.FC<IDetailLine> = observer((props: IDetailLine) => {
  // const [showLWH, setShowLWH] = useState(false);

  const detail = AppState.state.details[props.rowIndex];

  const updateDetail = (updatedDetail: Detail, index: number) => {
    const newDetails = AppState.state.details.map((d, i) => {
      if (i === index) {
        return updatedDetail;
      }
      return d;
    });
    AppState.updateDetails(newDetails);
  };

  const deleteDetail = (index: number) => {
    const filtered = AppState.state.details.filter((d, i) => i !== index);
    if (filtered.length === 0) {
      filtered.push(DefaultDetail);
    }
    AppState.updateDetails(filtered);
  };

  const addDetail = (index: number) => {
    const newDetails = [];
    for (let i = 0; i < AppState.state.details.length; i++){
      newDetails.push(AppState.state.details[i]);
      if (i === index){
        newDetails.push(DefaultDetail);
      }
    }
    AppState.updateDetails(newDetails);
  };
  
  const updatePieces = (e: React.ChangeEvent<HTMLInputElement>) => {
    let pieces = parseInt(e.target.value, 10) || 0;
    pieces = pieces < 0 ? 0 : pieces;
    const cube = (detail.length && detail.width && detail.height) ? 
      getCft(detail.length, detail.width, detail.height, pieces) : 0;
    console.log("PcsCube", cube);
    const newDetail: Detail = { ...detail, pieces, cube };
    updateDetail(newDetail, props.rowIndex);
  };

  const updateType = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDetail: Detail = { ...detail, type: e.target.value.toUpperCase() };
    updateDetail(newDetail, props.rowIndex);
  };

  const updateLength = (e: React.ChangeEvent<HTMLInputElement>) => {
    const length = parseFloat(e.target.value) || 0;
    const cube = (detail.width && detail.height) ? getCft(length, detail.width, detail.height, detail.pieces) : 0;
    const newDetail: Detail = { 
      ...detail, 
      length,
      cube,
    };
    updateDetail(newDetail, props.rowIndex);
  };

  const updateWidth = (e: React.ChangeEvent<HTMLInputElement>) => {
    const width = parseFloat(e.target.value) || 0;
    const cube = (detail.height && detail.length) ? getCft(detail.length, width, detail.height, detail.pieces) : 0;
    const newDetail: Detail = { 
      ...detail, 
      width,
      cube,
    };
    updateDetail(newDetail, props.rowIndex);
  };

  const updateHeight = (e: React.ChangeEvent<HTMLInputElement>) => {
    const height = parseFloat(e.target.value) || 0;
    const cube = (detail.width && detail.length) ? getCft(detail.length, detail.width, height, detail.pieces) : 0;
    const newDetail: Detail = { 
      ...detail, 
      height,
      cube,
    };
    updateDetail(newDetail, props.rowIndex);
  };
  
  const updateCft = (e: React.ChangeEvent<HTMLInputElement>) => {
    const myCft = parseFloat(e.target.value) || 0;
    // const cbRt =  Math.cbrt(myCft);
    // const cbInches = +((cbRt * 12).toFixed(2));
    const newDetail: Detail = { 
      ...detail, 
      length: 0,
      width: 0,
      height: 0,
      cube: myCft,
    };
    updateDetail(newDetail, props.rowIndex);
  };

  const updateLbs = (e: React.ChangeEvent<HTMLInputElement>) => {
    const lbs = parseFloat(e.target.value) || 0;
    const newDetail: Detail = { ...detail, pounds: lbs };
    updateDetail(newDetail, props.rowIndex);
  };

  const updateEmco = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDetail: Detail = { ...detail, emco: e.target.value };
    updateDetail(newDetail, props.rowIndex);
  };

  const updateClass = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDetail: Detail = { ...detail, class: e.target.value };
    updateDetail(newDetail, props.rowIndex);
  };

  const updateFlash = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDetail: Detail = { ...detail, flash: e.target.value };
    updateDetail(newDetail, props.rowIndex);
  };
  
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.altKey && e.key === "="){
      e.preventDefault();
      e.stopPropagation();
      addDetail(props.rowIndex);
    }
    else if (e.altKey && e.key === "-"){
      e.preventDefault();
      e.stopPropagation();
      deleteDetail(props.rowIndex);
    }
  };
  return (
    <div className="shipment-detail">
      <div className="detail-input-cell">
        <div className="hide-md-up">Pcs</div>
        <div>
          <input
            pattern="[0-9]*"
            className="detail-input"
            inputMode="numeric"
            type="number"
            placeholder="0"
            onKeyDown={onKeyDown}
            value={detail.pieces === 0 ? "" : detail.pieces}
            onChange={updatePieces} />
        </div>
      </div>
      <div className="detail-input-cell">
        <div className="hide-md-up">Type</div>
        <input
          type="text"
          className="detail-input"
          value={detail.type}
          onKeyDown={onKeyDown}
          onChange={updateType} />
      </div>
      <div className="detail-input-cell">
        <div className="hide-md-up">Cft</div>
        <input
          type="number"
          value={detail.cube === 0 ? "" : detail.cube}
          pattern="[0-9]*"
          inputMode="numeric"
          placeholder="0"
          className="detail-input"
          onKeyDown={onKeyDown}
          onChange={updateCft} 
          />
      </div>
      <div className="detail-input-cell">
        <div className="hide-md-up">Length</div>
        <input
          type="number"
          pattern="[0-9]*"
          inputMode="numeric"
          placeholder="0"
          className="detail-input"
          value={detail.length}
          onKeyDown={onKeyDown}
          onChange={updateLength} />
      </div>
      <div className="detail-input-cell">
        <div className="hide-md-up">Width</div>
        <input
          type="number"
          pattern="[0-9]*"
          inputMode="numeric"
          placeholder="0"
          className="detail-input"
          value={detail.width}
          onKeyDown={onKeyDown}
          onChange={updateWidth} />
      </div>
      <div className="detail-input-cell">
        <div className="hide-md-up">Height</div>
        <input
          type="number"
          pattern="[0-9]*"
          inputMode="numeric"
          placeholder="0"
          className="detail-input"
          value={detail.height}
          onKeyDown={onKeyDown}
          onChange={updateHeight} />
      </div>
      <div className="detail-input-cell">
        <div className="hide-md-up">Lbs</div>
        <input
          type="number"
          pattern="[0-9]*"
          inputMode="numeric"
          placeholder="0"
          value={detail.pounds === 0 ? "" : detail.pounds}
          onKeyDown={onKeyDown}
          className="detail-input"
          onChange={updateLbs} />
      </div>
      <div className="detail-input-cell">
        <div className="hide-md-up">EMCO</div>
        <input
          type="text"
          value={detail.emco}
          className="detail-input"
          onKeyDown={onKeyDown}
          onChange={updateEmco} />
      </div>
      <div className="detail-input-cell">
        <div className="hide-md-up">Class</div>
        <input
          type="text"
          value={detail.class}
          className="detail-input"
          onKeyDown={onKeyDown}
          onChange={updateClass} />
      </div>
      <div className="detail-input-cell">
        <div className="hide-md-up">Flash</div>
        <input
          type="text"
          value={detail.flash}
          className="detail-input"
          onKeyDown={onKeyDown}
          onChange={updateFlash} />
      </div>
      <div className="hide-md-down detail-buttons">
        <ToolTip text="You can press 'Alt-' keys to remove a line ">
          <span className="fa fa-minus-circle" onClick={() => deleteDetail(props.rowIndex)}></span>
        </ToolTip>
        <span>&nbsp;</span>
        <ToolTip text="You can press 'Alt=' to add a new line">
          <span className="fa fa-plus-circle" onClick={() => addDetail(props.rowIndex)}></span>
        </ToolTip>
      </div>
      <div className="hide-md-up flex-row">
        <button
          onClick={() => deleteDetail(props.rowIndex)}
          className="button red">Delete</button>
      </div>
    </div>
  );
});

export default DetailLine;
