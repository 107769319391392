import AppState from "../AppState/AppStateStore";

export function BindKeys() {
  document.addEventListener("keyup", (e: KeyboardEvent) => {
    if (e.key === "Escape"){
      e.preventDefault();
      e.stopPropagation();
      AppState.clearForm();
    }
  });

  document.addEventListener("keydown", async (e: KeyboardEvent) => {

    if (e.altKey && e.key.toLowerCase() === "p"){
      e.preventDefault();
      e.stopPropagation();
      if (AppState.state.updateType !== "Confirm"){
        AppState.selectSaveType("PreAlert");
      }      
    }

    if (e.altKey && e.key.toLowerCase() === "c"){
      e.preventDefault();
      e.stopPropagation();
      AppState.selectSaveType("Confirm");      
    }

    if (e.ctrlKey && e.altKey && e.key.toLowerCase() === "s"){
      e.preventDefault();
      e.stopPropagation();
      await AppState.save();
    }
    else if (e.ctrlKey && e.key.toLowerCase() === "s"){
      e.preventDefault();
      e.stopPropagation();
      await AppState.save("Save");
    }
  });
}
