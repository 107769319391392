import React from "react";
import { observer } from "mobx-react";
import AppState from "./AppState/AppStateStore";
import { format } from "date-fns";

const LastUpdate: React.FC = observer(() => {
  function getLastUpdateString(){
    switch (AppState.state.updateType){
      case "Save": return "Saved";
      case "Confirm": return "Confirmed";
      case "PreAlert": return "PreAlert";
      default: return "Save";
    }
  }
  return (
    <div>
      {AppState.state.lastUpdate && (
        <div className="app-cell">
          <label className="last-updated"><strong>Last Updated</strong></label>
          <div>{getLastUpdateString()}</div>
          <div>{AppState.state.lastUpdateUser}</div>
          <div>{format(AppState.state.lastUpdate, "MM/dd/yyyy hh:mm a")}</div>
        </div>
      )}
    </div>
  );
});

export default LastUpdate;
