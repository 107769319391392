import React from "react";
import { observer } from "mobx-react";
import AccountState from "./AccountState";
import AppState from "./AppState/AppStateStore";
import logo from "./images/CaroTrans_sm_dr.png";
import { Link, useNavigate } from "react-router-dom";

const NavBar: React.FC = observer(() => {

  const navigate = useNavigate();

  function logout() {
    AccountState.logOut();
    navigate("/");
  }

  return (
    <div className="nav-bar">
      <div className="nav-options">
        <div>
          <Link to="/lotStatus">Lots</Link>
          <Link to="/guide">Guide</Link>
        </div>
      </div>
      <div className="carotrans-logo">
        <Link to="/">
          <img src={logo} alt="CaroTrans DR Logo" />
        </Link>
      </div>
      <div className="user-name"
        onMouseEnter={() => AppState.setShowLogout(true)}
        onMouseLeave={() => AppState.setShowLogout(false)}>
        <div>
          <span className="fa fa-user">&nbsp;</span>
          <span>{AccountState.userState.userInfo.userName}</span>
        </div>
        {
          AppState.showLogout && (<div onClick={logout} className="logout">Logout</div>)
        }
      </div>
    </div>
  );
});

export default NavBar;
