import React from "react";
import ToolTip from "../Tooltip";
import { Description, DefaultDescription } from "../interfaces/DockReceipt";
import { autoExpand } from "../utilities/textAreaHelper";

interface IDescriptions {
  descriptions: Description[];
  updateDescriptions: (descriptions: Description[]) => void;
  title: string;
  lotTally: number;
  osAndD?: boolean;
}


class Descriptions extends React.PureComponent<IDescriptions> {
  node: HTMLDivElement | null = null;
  componentDidUpdate(prevProps: IDescriptions){
    if (this.props.lotTally !== prevProps.lotTally && this.node){
      const lines = this.node.querySelectorAll("textArea");
      lines.forEach(line => autoExpand(line as HTMLTextAreaElement));
    }
  }
  updateDescription = (description: string, index: number) => {
    const limit = this.props.osAndD ? 60 : 255;
    if (description.length > limit){
      description = description.substr(0, limit);
    }
    const newDescriptions = this.props.descriptions.map((x, i) => {
      if (i === index){
        return { 
          id: x.id,
          text: description,          
         };
      }
      return x;
    });
    this.props.updateDescriptions(newDescriptions);
  }
  addDescription = (index: number) => {
    if (this.props.osAndD && this.props.descriptions.length >= 2){
      return;
    }
    const newDescriptions: Description[] = [];
    for (let i = 0; i < this.props.descriptions.length; i++){
      newDescriptions.push(this.props.descriptions[i]);
      if (i === index){
        newDescriptions.push(DefaultDescription);
      }
    }
    this.props.updateDescriptions(newDescriptions); 
    this.focusLastLine();
  }
  focusLastLine = () => {
    setTimeout(() => {
      if (this.node && this.props.descriptions.length > 0) {
        const index = this.props.descriptions.length - 1;
        const lastLine = this.node.querySelector(`textArea[data-description-index="${index}"]`);
        if (lastLine){
          (lastLine as any).focus();
        }        
      }
    }, 100);    
  }
  removeDescription = (index: number) => {
    const newDescriptions = this.props.descriptions.filter((x, i) => i !== index);
    if (newDescriptions.length === 0){
      newDescriptions.push(DefaultDescription);
    }
    this.props.updateDescriptions(newDescriptions);
  }
  onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>, index: number) => {
    if (e.altKey && e.key === "="){
      e.preventDefault();
      this.addDescription(index);
    }
    else if (e.altKey && e.key === "-"){
      this.removeDescription(index);
    }
    else if (e.key === "ArrowDown" && this.node){
      const nextLine = this.node.querySelector(`textarea[data-description-index="${index + 1}"]`);
      if (nextLine){
        (nextLine as HTMLElement).focus();
      }
    }
    else if (e.key === "ArrowUp" && this.node){
      const nextLine = this.node.querySelector(`textarea[data-description-index="${index - 1}"]`);
      if (nextLine){
        (nextLine as HTMLElement).focus();
      }
    }
  }
  render(){
    return (                                                                         
      <div ref={node => this.node = node}>
        <h4>{this.props.title}</h4>
        {
          this.props.descriptions.map((x, i) => (
            <div key={i} className="description-div">
              <textarea className="input description-input"
              rows={1}
              data-desc
              value={x.text} 
              data-description-index={i}
              onKeyDown={(e) => this.onKeyDown(e, i)}
              onChange={(e) => this.updateDescription(e.target.value, i)}/>
              <ToolTip text="Press Alt- to remove a line">
                <span tabIndex={-1}
                      onClick={() => this.removeDescription(i)} 
                      className="fa fa-minus-circle">&nbsp;</span>
              </ToolTip>
              <ToolTip text="Press Alt= to add a line">
                <span tabIndex={-1}
                      onClick={() => this.addDescription(i)}  
                      className="fa fa-plus-circle"></span>
              </ToolTip>
            </div>
          ))
        }
      </div>
    );
  }
}

export default Descriptions;
