function getApiAddress() {
    if (window.location.hostname.toLowerCase().indexOf("carotrans.com") > -1) {        
        if (window.location.hostname.toLowerCase().indexOf("dockreceiptsqa") > -1) {
            return "https://apidmzqa1.carotrans.com/v2/";
        }
        else if (window.location.hostname.toLowerCase().indexOf("dockreceiptsuat") > -1) {
            return "https://apiuat.carotrans.com/v2/";
        }
        else {
            return "https://api.carotrans.com/v2/";
        }

    }
    else {
        return "https://localhost:44313/";
    }
}

export default {
    api: getApiAddress(),
};
