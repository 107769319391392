
export interface Shipper {
  id: string;
  company: string;
  name: string;
  streetPo: string;
  streetPo2: string;
  city: string;
  regionCode: string;
  postalCode: string;
  country: string;
  phone: string;
  fax: string;
  contact: string;
  emailAddress: string;
}

export const DefaultShipper: Shipper = {
  id: "",
  company: "",
  name: "",
  streetPo: "",
  streetPo2: "",
  city: "",
  regionCode: "",
  postalCode: "",
  country: "USA",
  phone: "",
  fax: "",
  contact: "",
  emailAddress: "",
};
