import React, { useEffect } from "react";
import { Routes, Route, Link } from "react-router-dom";
import "./App.scss";
import "./normalize.css";
import LoginPage from "./LoginPage/LoginPage";
import MainPage from "./MainPage";
import { observer } from "mobx-react";
import AccountState from "./AccountState";
import NavBar from "./NavBar";
import HowTo from "./HowTo/HowTo";
import LotStatus from "./LotStatus/LotStatus";
import AppState from "./AppState/AppStateStore";
import { BindKeys } from "./utilities/keyHelper";

const App = observer(() => {
  useEffect(() => {
    AppState.loadLotTallies();
    BindKeys();
  }, []);
  return (
    <div className="App">
      {
        AccountState.userState.isLoggedIn ? (
          <>
            <NavBar />
            <Routes>
              <Route path="/" element={<MainPage />} />
              <Route path="/guide" element={<HowTo />} />
              <Route path="/lotStatus" element={<LotStatus />} />
              <Route path="/lotTally/:lotTally" element={<MainPage />} />
            </Routes>
          </>
        ) : (
          <LoginPage />
        )
      }
    </div>
    // <div className="App">
    //   {
    //     AccountState.userState.isLoggedIn ? (
    //       <div>
    //         <NavBar />
    //         <Route path="/"><MainPage /></Route>
    //         <Link to="/guide"><HowTo /></Link>
    //         <Link to="/lotStatus"><LotStatus /></Link>
    //         <Link to="/lotTally/:lotTally"><MainPage /></Link>
    //       </div>
    //     ) : (
    //       <LoginPage />
    //     )
    //   }
    // </div>
  );
});

export default App;

