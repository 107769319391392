import Guid from "../interfaces/Guid";

export interface Detail {
  id: Guid;
  pieces: number;
  type: string;
  length: number;
  width: number;
  height: number;
  pounds: number;
  emco: string;
  class: string;
  flash: string;
  cube: number;
}

export const DefaultDetail: Detail = {
  id: new Guid(),
  pieces: 0,
  type: "PLT",
  length: 0,
  width: 0,
  height: 0,
  pounds: 0,
  emco: "",
  class: "",
  flash: "",
  cube: 0,
};

export const IsDefault = (shipmentDetail: Detail): boolean => {
  return (
    shipmentDetail.pieces === 0 &&
    shipmentDetail.type === "" &&
    shipmentDetail.length === 0 &&
    shipmentDetail.width === 0 &&
    shipmentDetail.height === 0 &&
    shipmentDetail.pounds === 0 &&
    shipmentDetail.emco === "" &&
    shipmentDetail.class === "" &&
    shipmentDetail.flash === ""
    );
};

export function getCft(length: number, width: number, height: number, pieces: number) {
  if (length && width && height && pieces){
    const cubicFeet = (length * width * height * pieces) / 1728; //Convert cubic inches to cubic feet
    return parseFloat(cubicFeet.toFixed(2));
  }
  return 0;
}

export function getCftFromDetail(detail: Detail) {
  if (detail.length && detail.width && detail.height){
    return getCft(detail.length, detail.width, detail.height, detail.pieces);
  }
  return detail.cube;
}

