import React, { ChangeEvent } from "react";
import phetch from "../utilities/phetch";
import dialog from "../utilities/dialog";
import PasswordResetModel from "./PasswordResetModel";
import Modal from "../Modal/Modal";

interface IPasswordResetModal {
  visible: boolean;
  onTryClose: () => void;
}

interface State {
  username: string;
  email: string;
}

class PasswordResetModal extends React.Component<IPasswordResetModal, State> {
  state: State = {
    username: "",
    email: "",
  };
  updateUserId = (e: ChangeEvent<HTMLInputElement>) => this.setState({ username: e.target.value });
  updateEmail = (e: ChangeEvent<HTMLInputElement>) => this.setState({ email: e.target.value });
  sendResetEmail = async () => {
    try{
      const reply = await phetch.get<PasswordResetModel>("account/passwordReset/sendEmail", {
        email: this.state.email,
        userId: this.state.username,
      });
      if (reply){
        dialog.alert(
          `An email to reset the password for ${reply.userId} has been sent to ${reply.email}.`, 
          "pwResetModal");
      }
    }
    catch (err: any){
      if (err.message){
        dialog.alert(err.message);
      }
      else{
        dialog.alert("There was an error attempting to reset your password");
      }
    }
  }
  render() {
    return (
      <Modal
        title="Reset Password"
        visible={this.props.visible}
        onTryClose={this.props.onTryClose}
      >
        <div className="login-pw-modal">
          <div>Enter your username and email to reset your password</div>
          <div className="login-pw-input-form">
            <div>
              <div>
                <input value={this.state.username}
                  onChange={this.updateUserId}
                  className="input-bg"
                  id="reset-username"
                  type="text"
                  placeholder="User Name" />
              </div>
              <div className="password-reset-row">
                <input value={this.state.email}
                  onChange={this.updateEmail}
                  className="input-bg"
                  id="email"
                  type="text"
                  placeholder="Email Address" />
              </div>
              <div className="password-reset-row">
                <button onClick={this.sendResetEmail} className="button blue login-button">Request Reset</button>
              </div>
            </div>
          </div>
        </div>

      </Modal>
    );
  }
}

export default PasswordResetModal;
