import React, { ReactElement } from "react";
import "./AutoComplete.scss";

interface IAutoComplete<T> {
  onSelect: (item: T) => void;
  items: T[];
  renderItem: (item: T, isHighlighted: boolean) => ReactElement;
  itemFilter: (item: T) => string | string[];
  textBoxValue: (item: T) => string;
  showDropDownArrow: boolean;
  inputId: string;
  keySelector?: (item: T) => any;
  defaultText?: string;
  inputClass?: string | undefined;
  onInputUpdate?: (value: string) => void;
  onUnknownSelection?: (value: string) => void;
}

interface State<T> {
  inputBoxText: string;
  highlightIndex: number;
  displayItems: T[];
}

class AutoComplete<T> extends React.Component<IAutoComplete<T>, State<T>> {
  state: State<T> = {
    inputBoxText: "",
    highlightIndex: -1,
    displayItems: [],
  };
  node: Node | null = null;
  componentDidMount() {
    this.setState({ inputBoxText: this.props.defaultText || "" });
  }
  componentWillMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }
  componentDidUpdate(prevProps: IAutoComplete<T>) {
    if (this.props.defaultText !== undefined && prevProps.defaultText !== this.props.defaultText) {
      this.setState({ inputBoxText: this.props.defaultText });
    }
  }
  updateInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputBoxText = e.target.value;
    this.setState({
      inputBoxText,
      highlightIndex: -1,
      displayItems: this.getFilteredItems(inputBoxText),
    });
    if (this.props.onInputUpdate) {
      this.props.onInputUpdate(inputBoxText);
    }
  }
  getFilteredItems = (filterText: string) => {
    const filtered = this.props.items.filter(x => {
      const filterReturn = this.props.itemFilter(x);
      let matches: string[];
      if (typeof filterReturn === "object") {
        matches = filterReturn;
      }
      else {
        matches = [filterReturn];
      }
      const inputLowerCase = filterText.toLowerCase();
      const match = matches.find(m => m.toLowerCase().startsWith(inputLowerCase));
      return !!match;
    });
    return filtered;
  }
  selectItem = () => {
    const selectedItem = this.getSelectItem();
    if (selectedItem){
      this.props.onSelect(selectedItem);
      const inputBoxText = this.props.textBoxValue(selectedItem);
      this.setState({ inputBoxText, displayItems: [] });
    }
    else if (this.props.onUnknownSelection){
      this.props.onUnknownSelection(this.state.inputBoxText);
    }
  }
  getSelectItem = (): T | undefined => {
    if (this.state.highlightIndex === -1 && this.state.displayItems.length > 0) {
      return this.state.displayItems[0];
    }
    //This could result in an undefined return.
    return this.state.displayItems[this.state.highlightIndex];
  }
  updateHighlight = (highlightIndex: number, e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ highlightIndex });
  }
  getKey = (item: T) => {
    if (!item){
      return "";
    }
    if (this.props.keySelector) {
      return this.props.keySelector(item);
    }
    return this.props.textBoxValue(item);
  }
  inputKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "ArrowDown") {
      this.setState(pevState => ({ highlightIndex: pevState.highlightIndex + 1 }));
      e.stopPropagation();
      e.preventDefault();
    }
    if (e.key === "ArrowUp") {
      this.setState(pevState => ({ highlightIndex: pevState.highlightIndex - 1 }));
      e.stopPropagation();
      e.preventDefault();
    }
    if (e.key === "Enter") {
      const selected = (this.state.displayItems[this.state.highlightIndex]);
      if (selected){
        this.props.onSelect(selected);
      }
      e.stopPropagation();
      e.preventDefault();
      this.selectItem();
    }
    if (e.key === "Tab") {
      const selected = this.state.displayItems[this.state.highlightIndex];
      if (selected){
        this.props.onSelect(selected);
      }
      this.selectItem();
    }
  }
  toggleShowAll = () => {
    this.setState((prevState) => {
      if (prevState.displayItems.length === 0) {
        return { displayItems: this.props.items };
      }
      return { displayItems: [] };
    });
  }
  handleClick = (e: MouseEvent) => {
    if (this.node && e.target && !this.node.contains(e.target as Node)) {
      this.setState({ displayItems: [] });
    }
  }
  inputFocus = (e: React.FocusEvent) => {
    if (!this.state.inputBoxText) {
      this.setState({ displayItems: this.props.items });
    }
  }
  render() {
    return (
      <div ref={node => this.node = node}>
        <div className="combo-box">
          <div>
            <input value={this.state.inputBoxText}
              onKeyUp={this.inputKeyUp}
              onChange={this.updateInput}
              onFocus={this.inputFocus}
              id={this.props.inputId}
              className={this.props.inputClass} />
          </div>
          <div style={{ display: this.props.showDropDownArrow ? "" : "none" }}
            onClick={this.toggleShowAll} className="dd-arrow">
            <span className="fa fa-chevron-down"></span>
          </div>
        </div>
        {
          this.state.displayItems.length > 0 && (
            <div className="dd-items">
              <div className="dd-items-inner">
              {this.state.displayItems.map((x, i) => (
                <div onMouseEnter={(e) => this.updateHighlight(i, e)}
                  onMouseLeave={(e) => this.updateHighlight(-1, e)}
                  onClick={this.selectItem}
                  key={this.getKey(x)}
                >
                  {this.props.renderItem(x, i === this.state.highlightIndex)}
                </div>
              ))}
              </div>
            </div>
          )
        }
      </div>
    );
  }
}

export default AutoComplete;
