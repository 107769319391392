import React from "react";
import "./Modal.scss";

export interface IModal {
  title: string;
  visible: boolean;
  onTryClose: () => void;
  className?: string;
}

export default class Modal extends React.PureComponent<IModal> {
  handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.currentTarget === e.target){
      this.props.onTryClose();
    }
  }
  public render() {
    const myClassName = [this.props.className || "", "modal-content"].join(" ");
    return (
      <div onClick={this.handleClick} className="modal" style={{display: this.props.visible ? "block" : "none" }}>
        <div className={myClassName}>
          <div className="modal-header">
            <div className="modal-title">{this.props.title}</div>
            <span onClick={this.props.onTryClose} className="close fa fa-close fa-2x"></span>
          </div>
          <div className="modal-body">
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}
