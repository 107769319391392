import React, { useState } from "react";
import { observer } from "mobx-react";
import PasswordResetModal from "./PasswordResetModal";
import AccountState from "../AccountState";

const LoginForm = observer(() => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [showPwModal, setShowPwModal] = useState(false);
  const login = (e: React.MouseEvent) => {
    e.preventDefault();
    AccountState.logIn(userName, password);
  };
  return (
    <div>
      <h3 className="login-input-text">Log In to Your Account</h3>
      <div>
        <form className="login-input-form">
          <div>
            <div className="login-input-row">
              <label htmlFor="username" className="hidden" >User Name</label>
              <input value={userName}
                onChange={(e) => setUserName(e.target.value)}
                className="input-bg"
                id="username"
                type="text"
                placeholder="User Name" />
            </div>
            <div className="login-input-row">
              <label htmlFor="password" className="hidden">Password</label>
              <input value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="input-bg"
                id="password"
                placeholder="Password"
                type="password" />
            </div>
            <div>
              <button onClick={login} className="button blue login-button">Log In</button>
            </div>
          </div>
        </form>
        <div className="under-login">
          <div onClick={() => setShowPwModal(true)}>Forgot Password</div>
          {/* <div onClick={e => dialog.alert("Test")}>Sign Up</div> */}
        </div>
      </div>
      <div className="password-reset-modal">
      </div>
      <PasswordResetModal
        onTryClose={() => setShowPwModal(false)}
        visible={showPwModal}>
      </PasswordResetModal>
    </div>
  );
});

export default LoginForm;
