export interface DialogOptions{
  message: string;
  onCancel: () => void;
  onOk: () => void;
}

export default {
  alert(message: string, modalClassName?: string | undefined){
    const baseDiv = createElementWithClass("div", "modal");
    const modalContent = createElementWithClass("div", "modal-content", baseDiv);
    if (modalClassName){
      modalContent.classList.add(modalClassName);
    }
    else{
      modalContent.classList.add("modal-content-small");
       
    }
    const modalHeader = createElementWithClass("div", "modal-header", modalContent);
    const modalTitle = createElementWithClass("div", "modal-title", modalHeader);
    modalTitle.innerText = "";
    const closeButton = createElementWithClass("span", "close fa fa-close fa-2x", modalHeader);
    const modalBody = createElementWithClass("div", "modal-body", modalContent);
    const modalText = createElementWithClass("div", "", modalBody);
    modalText.innerText = message;
    const buttonRow = createElementWithClass("div", "modal-button-container", modalBody);
    const button = createElementWithClass("button", "modal-button", buttonRow);
    button.innerText = "OK";
    document.body.append(baseDiv);
    const closeModal = (e: MouseEvent) => {
      e.preventDefault();
      document.body.removeChild(baseDiv);
    };
    closeButton.onclick = closeModal;
    button.onclick = closeModal;
  },

  error(errors: string[] | string, modalClassName?: string | undefined){
    if (!Array.isArray(errors)){
      errors = [errors];
    }
    const baseDiv = createElementWithClass("div", "modal");
    const modalContent = createElementWithClass("div", "modal-content", baseDiv);
    if (modalClassName){
      modalContent.classList.add(modalClassName);
    }
    else{
      modalContent.classList.add("modal-content");
       
    }
    const modalHeader = createElementWithClass("div", "modal-header", modalContent);
    const modalTitle = createElementWithClass("div", "modal-title", modalHeader);
    modalTitle.innerText = "";
    const closeButton = createElementWithClass("span", "close fa fa-close fa-2x", modalHeader);
    const modalBody = createElementWithClass("div", "modal-body", modalContent);
    const modalText = createElementWithClass("div", "", modalBody);
    modalText.innerText = "Please correct the following errors";
    const ul = createElementWithClass("ul", "", modalText);
    for (const error of errors) {
      createElementWithClass("li", "", ul, error);
    }
    const buttonRow = createElementWithClass("div", "modal-button-container", modalBody);
    const button = createElementWithClass("button", "modal-button", buttonRow);
    button.innerText = "OK";
    document.body.append(baseDiv);
    const closeModal = (e: MouseEvent) => {
      e.preventDefault();
      document.body.removeChild(baseDiv);
    };
    closeButton.onclick = closeModal;
    button.onclick = closeModal;
  },

  confirm(message: string, ok: () => void, cancel?: () => void | undefined){
    console.log("ErrorMsg", message);
    const baseDiv = createElementWithClass("div", "modal");
    const modalContent = createElementWithClass("div", "modal-content modal-content-small", baseDiv);
    const modalHeader = createElementWithClass("div", "modal-header", modalContent);
    const modalTitle = createElementWithClass("div", "modal-title", modalHeader);
    modalTitle.innerText = "Alert";
    const closeButton = createElementWithClass("span", "close fa fa-close fa-2x", modalHeader);
    const modalBody = createElementWithClass("div", "modal-body", modalContent);
    const modalText = createElementWithClass("div", "", modalBody);
    modalText.innerText = message;
    const buttonRow = createElementWithClass("div", "modal-button-container", modalBody);
    const cancelButton = createElementWithClass("button", "modal-button modal-button-cancel", buttonRow);
    cancelButton.innerText = "Cancel";
    const okButton = createElementWithClass("button", "modal-button", buttonRow);
    okButton.innerText = "OK";
    document.body.append(baseDiv);
    const closeModal = (e: MouseEvent, callback?: () => void | undefined ) => {
      e.preventDefault();
      document.body.removeChild(baseDiv);
      if (callback){
        callback();
      }
    };
    cancelButton.onclick = (e) => closeModal(e, cancel);
    closeButton.onclick = (e) => closeModal(e, cancel);
    okButton.onclick = (e) => closeModal(e, ok);
  },

};

function createElementWithClass(tagName: string, className: string, parent?: Node, text?: string){
  const myElement = document.createElement(tagName);
  myElement.className = className;
  if (parent){
    parent.appendChild(myElement);
  }
  if (text){
    myElement.innerText = text;
  }
  return myElement;
}

