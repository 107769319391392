export const autoExpand = (field: HTMLTextAreaElement) =>  {

  // Reset field height
  field.style.height = "inherit";

  // Get the computed styles for the element
  const computed = window.getComputedStyle(field);

  // Calculate the height
  const height = parseInt(computed.getPropertyValue("border-top-width"), 10)
    + parseInt(computed.getPropertyValue("padding-top"), 10)
    + field.scrollHeight
    + parseInt(computed.getPropertyValue("padding-bottom"), 10)
    + parseInt(computed.getPropertyValue("border-bottom-width"), 10);

  field.style.height = height + "px";

};

document.addEventListener("input", (event: any) => {
  if (event.target.tagName.toLowerCase() === "textarea"){
    autoExpand(event.target);
  }
}, false);


document.addEventListener("input", (event: any) => {
  if (event.target.id === "proNumber"){
    const proNumberInput = document.getElementById("proNumber") as HTMLInputElement;
    if (proNumberInput.value.length >= 16){
      proNumberInput.classList.add("input-fix-overflow-2");
    }
    else if (proNumberInput.value.length >= 12){
      proNumberInput.classList.remove("input-fix-overflow-2");
      proNumberInput.classList.add("input-fix-overflow");
    }
    else{
      proNumberInput.classList.remove("input-fix-overflow");
      proNumberInput.classList.remove("input-fix-overflow-2");
    }
  }
}, false);


document.addEventListener("input", (event: any) => {
  if (event.target.id === "lot-tally"){
    const myInput = document.getElementById("lot-tally") as HTMLInputElement;
    console.log("HIT", myInput);
    if (myInput.value.length >= 7){
      myInput.classList.add("input-fix-overflow-2");
    }
    else if (myInput.value.length >= 5){
      myInput.classList.remove("input-fix-overflow-2");
      myInput.classList.add("input-fix-overflow");
    }
    else{
      myInput.classList.remove("input-fix-overflow");
      myInput.classList.remove("input-fix-overflow-2");
    }
  }
}, false);


