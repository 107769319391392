import React from "react";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";


const LoggingInPage: React.FC = observer(() => {
  return (
    <div className="login-pw-modal">
      <div style={{marginBottom: 20}}>
        <FontAwesomeIcon icon={faSync as IconProp} className="fa-spin" />
      </div>
      <h2>
        Please Wait While We Log You In
      </h2>
    </div>
  );
});

export default LoggingInPage;
