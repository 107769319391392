import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Shipper, DefaultShipper } from "./Shipper";
import phetch from "../utilities/phetch";
import dialog from "../utilities/dialog";
import AutoComplete from "../AutoComplete/AutoComplete";
import ShipperModal from "./ShipperModal";
import AppState from "../AppState/AppStateStore";
import AccountState from "../AccountState";
import "./Shipping.scss";

const ShipperInfo: React.FC = observer(() => {
  const [editShipperModalOpen, setEditShipperModalOpen] = useState(false);
  const [addShipperModalOpen, setAddShipperModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [autoCompleteDefaultText, setAutoCompleteDefaultText] = useState<string | undefined>(undefined);
  useEffect(() => {
    const value = AppState.shipper ? AppState.shipper.name : "";
    setAutoCompleteDefaultText(value);
  }, [AppState.shipper]);
  const closeEditShipperModal = async (shipper: Shipper | undefined) => {
    if (shipper) {
      setSaving(true);
      try {
        const updatedShipper = await phetch.put<Shipper>("dockReceipts/shipper", shipper);
        const existingShippers = AppState.shippers.filter(x => x.id !== updatedShipper.id);
        const newShipperArray = [updatedShipper, ...existingShippers];
        setEditShipperModalOpen(false);
        setSaving(false);
        AppState.setShippers(newShipperArray);
        AppState.updateShipper(updatedShipper.id);
        setAutoCompleteDefaultText(updatedShipper.name);
      }
      catch (err: any) {
        const errMessage = err.message || "There was an error updating this shipper. Please try again, " +
          "and if the problem persists contact the CaroTrans Help Desk.";
        dialog.alert(errMessage);
      }
    }
    else {
      setEditShipperModalOpen(false);
    }
  };
  const closeAddShipperModal = (shipper: Shipper | undefined) => {
    async function innerCloseShipperModal(){
      if (!shipper){
        return;
      }
      try {
        shipper.company = AccountState.userState.userInfo.dockReceiptCompany;
        const newShipper = await phetch.post<Shipper>("dockReceipts/shipper", shipper);
        const allShippers = [...AppState.shippers, newShipper];
        setAddShipperModalOpen(false);
        AppState.setShippers(allShippers);
        AppState.updateShipper(newShipper.id);
        setSaving(false);
        setAutoCompleteDefaultText(newShipper.name);
      }
      catch (err: any) {
        const errMessage = err.message || "There was an error adding this shipper. Please try again, " +
          "and if the problem persists contact the CaroTrans Help Desk.";
        dialog.alert(errMessage);
      }
    }
    if (shipper) {
      setSaving(true);
    }
    else {
      setAddShipperModalOpen(false);
      AppState.updateShipper("");
      setAutoCompleteDefaultText("");
    }
    innerCloseShipperModal();
  };
  const selectShipper = (mySelectedShipper: Shipper | undefined) => {
    if (!mySelectedShipper){
      return;
    }
    if (mySelectedShipper.name === "++Add New Shipper++") {
      setAddShipperModalOpen(true);
      AppState.updateShipper("");
    }
    else {
      AppState.updateShipper(mySelectedShipper.id);
    }
  };
  const addShipper = { ...DefaultShipper, name: "++Add New Shipper++" };
  return (
    <div className="shipper-info">
        <label className="form-label">Shipper</label>
        <div className="shipper-input-autocomplete">
          <div>
            <AutoComplete<Shipper>
              items={[addShipper, ...AppState.shippers]}
              itemFilter={s => s.name}
              keySelector={s => s.id}
              textBoxValue={s => s.name}
              defaultText={autoCompleteDefaultText}
              showDropDownArrow={true}
              inputClass="input"
              inputId="shipper-id"
              renderItem={(item: Shipper, isHighlighted: boolean) =>
                <div key={item.id} className={isHighlighted ? "dd-item-highlight dd-item" : "dd-item"}>
                  {item.name}
                </div>
              }
              onSelect={selectShipper}
            />
            <div>
              {AppState.shipper && (
                <div>
                  {
                  AppState.shipper.streetPo && (<div>{AppState.shipper.streetPo}</div>)}
                  {AppState.shipper.streetPo2 && (<div>{AppState.shipper.streetPo2}</div>)}
                  {
                    (AppState.shipper.city || 
                      AppState.shipper.regionCode || 
                      AppState.shipper.postalCode) && (
                        <div className="address-line">
                          <span>{AppState.shipper.city}</span>
                          <span>{AppState.shipper.regionCode}</span>
                          <span>{AppState.shipper.postalCode}</span>
                        </div>
                      )
                  }
                  <div>{AppState.shipper.country}</div>
                </div>
              )}
              {!AppState.shipper && (
                <div>
                  <div>&nbsp;</div>
                </div>
              )}
            </div>
          </div>
          <span
            style={{ visibility: AppState.shipper ? "visible" : "hidden" }}
            onClick={() => setEditShipperModalOpen(true)}
            className="fa fa-edit fa-lg shipper-edit"></span>
        </div>

        <ShipperModal
          buttonWorking={saving}
          initialShipperData={AppState.shipper}
          visible={editShipperModalOpen}
          onTryClose={closeEditShipperModal} />

        <ShipperModal
          buttonWorking={saving}
          visible={addShipperModalOpen}
          onTryClose={closeAddShipperModal} />

      </div>
  );
});

export default ShipperInfo;
