import { parseISO } from "date-fns";

const defaultDate = new Date(1900, 0, 1);

function getDate(dateString: string): Date {  
  if (!dateString){
    return defaultDate;
  }
  const myDate = parseISO(dateString);
  console.log("myDate", myDate);
  if (isNaN(myDate.getTime())) {
    return defaultDate;
  } else {
    return myDate;
  }
}

export {getDate};
