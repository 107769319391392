import React from "react";
import logo from "../images/CaroTrans_sm_dr.png";
import "./LoginPage.css";
import { observer } from "mobx-react";
import AccountState from "../AccountState";
import LoggingInPage from "./LoggingInPage";
import LoginForm from "./LoginForm";

const LoginPage = observer(() => {


  
  return (
    <div className="login-page">
      <img src={logo} className="app-logo login-logo" alt="logo" />
      {
        AccountState.loggingIn ? (
          <LoggingInPage/>
        ) : (
          <LoginForm/>
        )
      }
    </div>
  );
});


export default LoginPage;
