import React from "react";
import "./HowTo.css";
import { Link } from "react-router-dom";

const HowTo: React.FC = () => {
  return (
    <div className="tips">
      <div>        
        <div>
        <h1>Main Form</h1>
          <p>The CaroTrans Dock Receipt app is a simple form for sending Dock Receipts to CaroTrans.</p>
          <p>
            The form fields are mostly self-explanatory, but here are a few guidelines:
            <ul>
              <li><span className="field">Lot/Tally</span> This is a reference number. When you save a new 
              shipment, a new Lot/Tally will be generated. If you wish you to bring an existing Lot/Tally back
              up to view or change, you may select it from the dropdown or type it into the field.</li>

              <li><span className="field">Clear</span> This button clears out any existing Lot/Tally Information
              when preparing to enter a new shipment.</li>

              <li><span className="field">Shipper</span> The shipper is required before sending the shipment
              to CaroTrans as a PreAlert or Confirmation. To add a new shipper, select "Add New Shipper" from the 
              drop down. When adding a new shipper, the shipper's name is the only required field.</li>

              <li><span className="field">Shipper</span> The shipper is required before sending the shipment
              to CaroTrans as a PreAlert or Confirmation. To add a new shipper, select "Add New Shipper" from the 
              drop down. When adding a new shipper, the shipper's name is the only required field.</li>

              <li><span className="field">Booking Number</span> The CaroTrans booking number. This is 
              required before sending a shipment to CaroTrans as a PreAlert or Confirmation. This is 
              always a 15 character code, in the format of ABCDEF1234567</li>
              (ie, the first six characters are letters, and the final seven are numbers).

              <li><span className="field">Shipper Reference</span> The shipper's reference or booking number. T
              his field is not required.</li>

              <li><span className="field">Container Number</span> The container number. This field is not required.</li>

              <li><span className="field">Received Date/Time</span> The date the shipment was received at 
              the warehouse. This field is required to send to CaroTrans</li>

              <li><span className="field">Loaded Date</span> The date the shipment was loaded.  This field 
              is not required.</li>

              <li><span className="field">Loaded Date</span> The date the shipment was loaded. This field 
              is not required.</li>

              <li><span className="field">Trucker</span> The name of the trucker. This field is not required.</li>

              <li><span className="field">Descriptions</span> Any descriptions for the shipment. Pressing the + 
              button will add a line. An example might be "4 SW PLTS W/ CNTS. 3  HT OK"</li>

              <li><span className="field">Marks &amp; Nums</span> Any marks or information on the pallet. 
              Pressing the + button will
              add a line. An example might be "Package S0000468070: TOTAL CARGO 4 PLT NO EXPORT."</li>

              <li><span className="field">OS&amp;D</span> Any OS&amp;D information for the pallet. Pressing 
              the + button will add a second line. There can be no more than two lines. An example might be 
              "7 HEAT TREATED PALLETS WITH BOXES"</li>

              <li><span className="field">OS&amp;D</span> Any OS&amp;D information for the pallet. 
              Pressing the + button will add a second line. There can be no more than two lines. 
              An example might be "7 HEAT TREATED PALLETS WITH BOXES"</li>

              <li><span className="field">Details</span> You must at least one detail line for a 
              shipment to send a PreAlert or Confirmation to CaroTrans.
                Each detail line contains the following fields
                <ul>
                  <li><span className="field">Pcs</span> The total number of pieces.</li>
                  <li><span className="field">Type</span> The shipment type. For instance "PLT" or "BOX"</li>
                  <li><span className="field">Cft</span> The total cubic feet for this line. 
                  For instance, if you had two pieces that warehouse 20 cft each, you would enter 40 here.
                  This value will be auto-calculated if you updat the length, width, height, and pcs fields.
                  </li>
                  <li><span className="field">Length</span> The length, in inches.</li>
                  <li><span className="field">Width</span> The width, in inches.</li>
                  <li><span className="field">Height</span> The height, in inches.</li>
                  <li><span className="field">Lbs</span> The weight in pounds.</li>
                  <li><span className="field">Pcs</span> The length, in inches.</li>
                  <li><span className="field">EMCO</span> EMCO information for a shipment. 
                    generally be included if the shipment is Hazardous.</li>
                  <li><span className="field">Class</span> Item class information.</li>
                  <li><span className="field">Flash</span> Item flash information</li>
                </ul>
              </li>
              <li><span className="field">Totals</span> These fields are auto-calculated</li>
              <li><span className="field">Save</span> These button saves your work. It does not send
                  any information to CaroTrans</li>
                <li><span className="field">Save and Send</span> This button saves your work and sends
                  the information to CaroTrans in the form of a PreAlert or Confirmation. It has two "modes"
                <ul>
                <li><span className="field">Send Pre-Alert</span> These sends a "pre-alert" to CaroTrans,
                  noting that the shipment has arrived, but that you are not yet ready to send a Confrimation,
                  perhaps because it has not been weighed (for instance) or some other reason that all
                  information has not yet been entered.</li>
                <li><span className="field">Send Confirmation</span> This sends a Confirmation to CaroTrans.
                  If you have already sent a Confirmation, this updates that information in the CaroTrans system.</li>
                </ul>
                </li>
                <li><span className="field">Manage Photos</span> This allows you to upload photos to CaroTrans.</li>
            </ul>

          </p>
        </div>

        <div>
          <h1>Lots</h1>
          <p>By clicking on the <Link to="/lots">Lots</Link> link (top left of the main navigation bar) you
          can access a list of all lots submitted to CaroTrans. Clicking on the Lot number on this screen will
          take you to the lot for editing. By default, this shows any Lots that still need to be Confirmed.</p>
          <p>
            On this screen, you will see a list of all lots that have not been sent as Confirmations. This includes
            lots that were previously sent as Confirmed, but were updated since the last time they were confirmed.
            For instance, a lot might be sent to CaroTrans as confirmed, but after that confirmation is sent,
            the weight is changed from 400 lbs to 430 lbs. The user then clicks "save" but not "Save and Send."
            Even though this shipment was confirmed in the past, the latest information has not been sent to CaroTrans,
            so it will show up on the Lot Status screen by default. It would appear on this screen as
            <div>
              <img src="./lotExample.jpg" alt="Example"/>
            </div>
            <p>
              This indicates that while a Pre-alert and Confirmation have been sent before (the green and blue check 
              marks), the most recent information saved has <em>not</em> been sent to CaroTrans.
            </p>
          </p>
          <p>
            Additionally, if you check the "Show Confirmed" check box, it will show <em>all</em> lot statuses,
            including those sent to CaroTrans as confirmations. This is useful for finding historical information.</p>
          <p>
            Finally, a user may narrow down their search by typing into the "FILTER" box. When text is entered here, 
            you will only see lots with matching information in the booking number, shipper refernece, customer 
            reference, etc.
          </p>
        </div>

        <h1>Shortcuts</h1>
        <ul>
          <li>
            <div><code>Esc</code> Clears the current LotTally</div>
          </li>
          <li>
            <div><code>Ctrl S</code> Saves the lot tally but WILL NOT send a Pre-Alert or Confirmation</div>
            <div>even if those options are checked.</div>
          </li>
          <li>
            <div><code>Ctrl Alt S</code> Saves the lot tally and WILL send a Pre-Alert or Confirmation</div>
            <div>if those options are checked.</div>
          </li>
          <li>
            <div><code>Alt C</code> Sets the save mode to "Confirm"</div>
          </li>
          <li>
          <div><code>Alt P</code> Sets the save mode to "Pre-Alert"</div>
          </li>
          <li>
            <div>When editing Descriptions, Marks and Numbers, or OS&amp;D Information, you may </div>
            <div>press <code>Alt =</code> to add a new line, or <code>Alt -</code> to remove one.</div>
          </li>
          <li>
            <div>When editing Descriptions, Marks and Numbers, or OS&amp;D Information, you may </div>
            <div>press the arrow keys to move up or down</div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default HowTo;
