import React from "react";
import "./photos.css";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone, { IFileWithMeta, StatusValue, IUploadParams } from "react-dropzone-uploader";
import environment from "../utilities/environment";
import phetch from "../utilities/phetch";

interface IPhotoManagement {
  lotTally: number;
}

interface State {
  photoLinks: string[];
}

class PhotoManagement extends React.Component<IPhotoManagement, State> {
  state: State = {
    photoLinks: [],
  };
  componentDidMount(){
    if (this.props.lotTally > 0){
      this.getPhotoLinks();
    }    
  }
  componentDidUpdate(prevProps: IPhotoManagement){
    if (prevProps.lotTally !== this.props.lotTally && this.props.lotTally > 0){
      this.getPhotoLinks();
    }
  }
  getPhotoLinks = async () => {
    const ids = await phetch.get<number[]>("dockReceipts/photoLinks/" + this.props.lotTally);
    const photoLinks = ids.map(id => environment.api + "dockReceipts/photo/" + id);
    this.setState({ photoLinks });
  }
  getUploadParams = (props: IFileWithMeta) => {
    const token = localStorage.getItem("apiToken");
    const myParams: IUploadParams = { 
      url: environment.api + "dockReceipts/photos",
      fields: {lotTally: this.props.lotTally.toString() },
      headers: {Authorization: "Bearer " + token },
    };
    return myParams;
  }
  // called every time a file's `status` changes
  handleChangeStatus = (file: IFileWithMeta, status: StatusValue, allFiles: IFileWithMeta[]) => { 
    if (status === "done"){
      this.getPhotoLinks();
      allFiles.forEach(f => f.remove());
    }
   }  
  // receives array of files that are done uploading when submit button is clicked
  handleSubmit = (successful: IFileWithMeta[], allFiles: IFileWithMeta[]) => { 
    console.log(successful.map(f => f.meta));
  } 
  render() {
    return (
      <div>
        {this.state.photoLinks.length === 0 ? (
          <div className="no-photos">
            <div>Upload a Photo</div>
          </div>
        ) : (
            <div>
              {this.state.photoLinks.map(link => (
                <span key={link}>
                  <a href={link} >
                    <img key={link} src={link} height="100px" alt="" />
                  </a>
                </span>
                
              ))}
            </div>
          )}
        <div>
        <Dropzone
          getUploadParams={this.getUploadParams}
          onChangeStatus={this.handleChangeStatus}
          onSubmit={this.handleSubmit}
          accept="image/*"
        />
        {/* <Dropzone onDrop={this.onDrop}>
          {({getRootProps, getInputProps}) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
              </div>
            </section>
          )}
        </Dropzone> */}
        </div>
      </div>
    );
  }
}

export default PhotoManagement;



