import { UserState, DefaultUserState, UserInformation } from "./interfaces/UserInfo";
import { observable, action, makeAutoObservable } from "mobx";
import { addDays } from "date-fns";
import phetch from "./utilities/phetch";
import { BearerToken } from "./interfaces/BearerToken";
import dialog from "./utilities/dialog";

export interface IAccountContext {
  logIn: (userName: string, password: string) => Promise<void>;
  isInRole: (role: string) => boolean;
  logOut: () => void;
  userState: UserState;
}

export class AccountStore implements IAccountContext {
  @observable userState: UserState = DefaultUserState;
  @observable loggingIn: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  @action.bound
  public async logIn(userName: string, password: string){
    this.loggingIn = true;
    const loginModel = {
      userName,
      password,
      grant_type: "password",
      expirationDate: addDays(new Date(), 20),
    };
    try{
      const bearerToken = await phetch.put<BearerToken>("account/token", loginModel, "PUT");
      if (bearerToken.access_token){
        localStorage.setItem("apiToken", bearerToken.access_token);
        await this.setUserState();
      }
    }
    catch (err: any){
      const alertMessage = (err.message) ? err.message : 
        "There was an error logging you in. Please make sure your username and password is correct";
      dialog.alert(alertMessage);
    }
    finally{
      this.loggingIn = false;
    }
  }

  @action.bound
  setUserState = async () => {
    const token = localStorage.getItem("apiToken");

    if (!token) {
      this.userState = DefaultUserState;
    }
    else{
      try{
        const info = await phetch.get<UserInformation>("account/info");
        this.userState = {
          isLoggedIn: true,
          userInfo: info,
        };
      }
      catch (err: any){
        this.userState = DefaultUserState;
      }
    }
  }

  @action.bound
  isInRole = (role: string): boolean => {
    role = role.toLowerCase();
    const found = this.userState.userInfo.roles.find(x => x.toLowerCase() === role);
    return !!found;
  }

  @action.bound
  logOut = async () => {
    localStorage.removeItem("apiToken");
    this.userState = DefaultUserState;
  }
}

const AccountState = new AccountStore();

AccountState.setUserState();

export default AccountState;
