import { Description, UpdateType } from "../interfaces/DockReceipt";
import { Detail, DefaultDetail } from "../Details/Detail";
import LotTallyDto from "../interfaces/LotTallyDto";
import { format } from "date-fns";
import { DefaultDescription } from "../interfaces/DockReceipt";

export interface IFormState  {
  id: string;
  containerNumber: string;
  receivedDate: string;
  receivedTime: string;
  loadedDate: string;
  bookingNumber: string;
  descriptions: Description[];
  marksAndNums: Description[];
  osAndDInformation: Description[];
  lotTally: number;
  warehouse: string;
  proNumber: string;
  shipperId: string;
  details: Detail[];
  lotTallies: LotTallyDto[];
  showPhotoManagement: boolean;
  shipperReference: string;
  hazardous: boolean;
  lastUpdate: Date | undefined;
  lastUpdateUser: string;
  updateType: UpdateType | undefined;
  trucker: string;
  confirmed: boolean;
  preAlerted: boolean;
}

export function getDefaultState(drCompany: string): IFormState {
  const now = new Date();
  return {
    id: "",
    containerNumber: "",
    bookingNumber: "",
    receivedDate: format(now, "yyyy-MM-dd"),
    receivedTime: format(now, "HH:mm"),
    loadedDate: "",
    descriptions: [DefaultDescription],
    marksAndNums: [DefaultDescription],
    osAndDInformation: [DefaultDescription],
    lotTally: 0,
    warehouse: drCompany.toUpperCase(),
    proNumber: "",
    shipperId: "",
    details: [DefaultDetail],
    lotTallies: [],
    showPhotoManagement: false,
    shipperReference: "",
    hazardous: false,
    lastUpdate: undefined,
    lastUpdateUser: "",
    trucker: "",
    updateType: undefined,
    confirmed: false,
    preAlerted: false,
  };
}
